.typewriter-editor {
  position: relative;
  cursor: text;
  contain: layout;
}
.special-padding-rule,
.fill-page .typewriter-editor {
  padding-bottom: 768px !important;
}

span.found {
  background: #fffd38;
}
span.found.found-selected {
  background: #fd963f;
}

.page h1,
.page h2,
.page h3,
.page h4,
.page h5,
.page h6,
.page header {
  margin: 1em 0;
  color: var(--text-color-light);
  font-family: var(--font-family-header);
  font-weight: normal;
}
.page h5,
.page h6 {
  margin: 1em 0 0.5em;
  font-weight: bold;
}

.page h1 {
  padding: 4rem 0;
  margin: 0;
  text-align: center;
  font-size: 3em;
}
.title-page h1 {
  padding-top: 0;
}
.page a {
  text-decoration: underline;
}
.page h2 {
  font-size: 2em;
}
.page p {
  font-family: var(--font-family-text);
  text-indent: var(--paragraph-indent);
  margin: var(--paragraph-margin) 0;
  line-height: var(--paragraph-line-spacing);
}

.page li {
  font-family: var(--font-family-text);
  line-height: var(--paragraph-line-spacing);
}

.page blockquote.quote-true {
  position: relative;
  margin: var(--paragraph-line-spacing) 0 var(--paragraph-line-spacing) calc(var(--paragraph-line-spacing) * 0.85);
  font-style: italic;
}

.page blockquote.quote-true:not(:first-child) p:first-child {
  text-indent: var(--paragraph-indent);
}

.page blockquote.quote-alt {
  position: relative;
  padding: 0 calc(var(--paragraph-line-spacing) * 0.85);
  margin: var(--paragraph-line-spacing) auto var(--paragraph-line-spacing);
  font-style: italic;
  text-align: center;
}
.page blockquote.quote-alt p {
  text-indent: 0;
}
.page blockquote:first-child {
  margin-top: 0;
}

.editable-content {
  display: flex;
  cursor: auto;
  position: relative;
  -webkit-user-select: auto;
  user-select: auto;
  contain: layout;
}
.typewriter-editor {
  flex: 1;
}

.typewriter-editor .placeholder {
  position: relative;
}
.typewriter-editor .placeholder::before {
  content: attr(data-placeholder);
  position: absolute;
  opacity: 0.5;
  font-style: italic;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: inherit;
  flex-direction: inherit;
  justify-content: inherit;
  align-content: inherit;
  padding: inherit;
  text-align: inherit;
}
/* .typewriter-editor:focus .placeholder::before {
  white-space: normal;
  overflow: visible;
} */
.editable-content.hidden-placeholder .typewriter-editor .placeholder::before {
  display: none;
}
.editable-content.hidden-placeholder .typewriter-editor:focus .placeholder::before {
  display: block;
}
.editable-content .typewriter-editor:focus .placeholder::before {
  opacity: 0.2;
}
.editable-content.unstyled-placeholder .typewriter-editor .placeholder::before {
  font-style: inherit;
  opacity: 1;
}
.editable-content.has-image {
  flex-wrap: wrap;
}
.editable-content.has-image .title-image {
  width: 100%;
  margin-top: 4.1rem;
  order: 0;
}
.editable-content.has-image .typewriter-editor {
  order: 1;
}
.editable-content.has-image h1 {
  padding: 1.5rem 0 4rem;
}
.editable-content.has-image.unstyled-placeholder .typewriter-editor {
  flex: 0 0 calc(100% + var(--page-padding) * 2);
}
.editable-content.has-image.unstyled-placeholder .title-image {
  margin-top: 0;
}
.title-prefix {
  position: absolute;
  display: block;
  font-size: 0.875rem;
  line-height: 0.875rem;
  width: 100%;
  text-transform: uppercase;
  line-height: 2;
  letter-spacing: var(--chapter-title-prefix-line-spacing);
  color: var(--text-color-lighter);
  opacity: 1;
  top: 1em;
  text-align: center;
  transition: top 300ms, opacity 300ms;
  padding-left: var(--chapter-title-prefix-line-spacing);
}

.typewriter-editor .format.decoration.selected {
  background: var(--selection-color);
}
.typewriter-editor.window-inactive {
  --selection-color: var(--selection-color-inactive);
}

.title-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  height: 1056px;
}
.title-page .editable-content[data-field='title'] {
  display: flex;
  flex-direction: column;
  margin-top: calc(-1 * var(--page-padding));
  padding-top: var(--page-padding); /* 1 inch */
}
.title-page .editable-content[data-field='title'].end {
  justify-content: flex-end;
}
.title-page h1 {
  font-size: 5em;
  color: var(--text-color-light);
  padding-bottom: 0;
}
.title-page h2 {
  font-size: 2.5em;
  color: var(--text-color-light);
  padding-bottom: 0;
}
.title-page .info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 10%;
}
.title-page .editable-content[data-field='author'] {
  height: 50%;
  margin-bottom: calc(-1 * var(--page-padding));
  padding-bottom: var(--page-padding); /* 1 inch */
}
.title-page h3 {
  font-size: 2em;
  color: var(--text-color-lighterer);
  padding-top: 0;
}
.title-page h4,
.title-page .info {
  font-size: 1em;
  text-transform: uppercase;
  color: var(--text-color-lighter);
}

.text-cursor {
  position: absolute;
  -webkit-user-select: none;
  user-select: none;
  pointer-events: none;
  width: 2px;
}
.text-cursor.cursor-blink {
  animation: blink 1s infinite;
}

.check-list-item {
  position: relative;
  list-style: none;
}
.check-list-check {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 0;
  left: -28px;
  border-radius: 3px;
  border: 1px solid #80bdff;
  cursor: pointer;
  transition: background-color 0.15s ease-in-out;
}
.check-list-item[data-checked='true'] .check-list-check {
  background-color: #80bdff;
}
.page {
  --selection-color: hsla(215, 100%, 77%, 0.6);
  --selection-color-light: hsla(215, 100%, 77%, 0.2);
}
.page.window-inactive {
  --selection-color: rgba(169, 169, 169, 0.6);
}
.page hr {
  margin: 0;
  padding: 16px;
  border: none;
  cursor: default;
  height: 1px;
  box-sizing: content-box;
  background-color: #ccc;
  background-clip: content-box;
}
.page hr.selected {
  box-shadow: inset 0 0 0 16px var(--selection-color-light);
  outline: 1px solid var(--selection-color);
  outline-offset: -1px;
}
.page .format.decoration.selected {
  background: var(--selection-color);
  padding-top: 9px;
  padding-bottom: 9px;
}
.page .selection-menu {
  background: var(--selection-color);
}
.page ul[type='dash'] {
  list-style-type: '– ';
}

/* Use as property list heading */
.page h6.dlh {
  font: inherit;
  font-weight: 600;
  margin: 1em 0 0.25em;
  color: var(--text-color-light);
}
.page dl {
  font-family: var(--font-family-text);
  margin: 0 0 var(--paragraph-margin) 0;
  line-height: var(--paragraph-line-spacing);
  --baseline: calc((var(--paragraph-line-spacing) - 1em) / 2 + 1em - 2px);
  --connector-line: linear-gradient(
    to bottom,
    transparent,
    transparent var(--baseline),
    var(--text-color-faint) var(--baseline),
    var(--text-color-faint) calc(var(--baseline) + 1px),
    transparent calc(var(--baseline) + 1px)
  );
}
.page dl > div {
  display: grid;
  grid-template-columns: 1fr 2fr;
}
.page dl dt {
  display: flex;
  grid-column: 1 / 2;
  margin-right: 4px;
  color: var(--text-color-lighter);
}
.page dl dt:has(br:only-child) {
  display: block;
  background: var(--connector-line);
  background-size: calc(100% - 4px) 100%;
  background-position: 3px 0;
  background-repeat: no-repeat;
}
.page dl dt:not(:has(br:only-child))::after {
  content: '';
  flex: 1 1;
  margin-left: 2px;
  background: var(--connector-line);
  pointer-events: none;
}
.page dl dd {
  grid-column: 2 / 3;
  margin: 0;
}

@media (max-width: 599.98px) {
  .page h1 {
    font-size: 2em;
  }
  .title-page h1 {
    font-size: 3em;
  }
  .title-page h2 {
    font-size: 2em;
  }
  .title-page h3 {
    font-size: 1.5em;
  }
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  35% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  85% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
