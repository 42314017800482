.editable-content .image {
  position: relative;
  cursor: default;
  margin: 1rem 0;
}
.editable-content .image img {
  position: absolute;
  width: 100%;
  height: 100%;
}
html[data-theme='dark'] .editable-content .image img {
  filter: brightness(0.8);
}
.editable-content .image:hover {
  outline: 4px solid var(--selection-color-light);
  outline-offset: 2px;
}
.editable-content .image.focus {
  outline: 4px solid var(--selection-color);
  outline-offset: 2px;
}
.editable-content .image.focus *::selection {
  background-color: rgba(0, 0, 0, 0);
}
.editable-content .image.focus *::selection {
  background-color: rgba(0, 0, 0, 0);
}
.editable-content .image.selected *::-moz-selection {
  background-color: rgba(0, 0, 0, 0);
}
.editable-content .image[data-style='outset-left'] {
  float: left;
  width: calc(50% + var(--page-padding));
  margin-left: calc(-1 * var(--page-padding));
  margin-right: 1em;
}
.editable-content .image[data-style='outset-center'] {
  max-width: 1032px;
  width: calc(100% + var(--page-padding) * 2);
  margin: 1em calc(-1 * var(--page-padding));
}
.editable-content .image[data-style='fill-width'] {
  margin-left: calc(-1 * var(--page-padding) - (var(--workspace-width) / 2 - var(--page-width) / 2));
  margin-right: calc(-1 * var(--page-padding) - (var(--workspace-width) / 2 - var(--page-width) / 2));
  width: var(--workspace-width);
}
.page:has(+ .comments) .editable-content .image[data-style='fill-width'] {
  margin-left: calc(-1 * var(--page-padding) + 272px / 2 - (var(--workspace-width) / 2 - var(--page-width) / 2));
  margin-right: calc(-1 * var(--page-padding) - 272px / 2 - (var(--workspace-width) / 2 - var(--page-width) / 2));
}
.editable-content .image[data-style='full-page'] {
  margin: 2rem 0;
}
.editable-content .image[data-style='full-page']::before,
.editable-content .image[data-style='full-page']::after {
  content: '';
  position: absolute;
  display: block;
  border-bottom: 1px dashed var(--gray-lightish);
  max-width: 1032px;
  width: calc(100% + var(--page-padding) * 2);
  margin: -1rem calc(-1 * var(--page-padding)) 0;
}
.editable-content .image[data-style='full-page']::after {
  margin: 1rem calc(-1 * var(--page-padding)) 0;
}
@media print {
  .editable-content .image[data-style='full-page'] {
    margin: 0;
  }
  .editable-content .image[data-style='full-page']::before,
  .editable-content .image[data-style='full-page']::after {
    display: none;
  }
}

.editable-content .image.blurred {
  overflow: hidden;
}
.editable-content .image.blurred img {
  filter: blur(50px);
}
/* @media (max-width: 816px) {
  .editable-content .image[data-style="fill-width"] {
    margin-left: calc(-1 * var(--page-padding) - (50vw - var(--page-width)/2));
    margin-right: calc(-1 * var(--page-padding) - (50vw - var(--page-width)/2));
  }
} */
