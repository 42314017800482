format-grammar {
  background-repeat: repeat-x;
  background-position: -1px calc(100% - 1px);
  padding-bottom: 1px;
}
format-grammar[data-category='spelling'] {
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0IiBoZWlnaHQ9IjQiPjxwYXRoIGQ9Ik0gMCAzIEwgMiAxIEwgNCAzIiBzdHJva2U9IiNGRjAwMDAiIGZpbGw9Im5vbmUiIHN0cm9rZS1saW5lY2FwPSJzcXVhcmUiLz48L3N2Zz4=');
}
format-grammar[data-category='grammar'] {
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0IiBoZWlnaHQ9IjQiPjxwYXRoIGQ9Ik0gMCAzIEwgMiAxIEwgNCAzIiBzdHJva2U9IiMwMDdDMDAiIGZpbGw9Im5vbmUiIHN0cm9rZS1saW5lY2FwPSJzcXVhcmUiLz48L3N2Zz4=');
}
format-grammar[data-category='style'] {
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0IiBoZWlnaHQ9IjQiPjxwYXRoIGQ9Ik0gMCAzIEwgMiAxIEwgNCAzIiBzdHJva2U9IiMwMDVEQkUiIGZpbGw9Im5vbmUiIHN0cm9rZS1saW5lY2FwPSJzcXVhcmUiLz48L3N2Zz4=');
}
.isApple format-grammar[data-category='spelling'] {
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0cHgiIGhlaWdodD0iM3B4Ij48ZGVmcz48bGluZWFyR3JhZGllbnQgeDE9IjUwJSIgeTE9IjAlIiB4Mj0iNTAlIiB5Mj0iMTAwJSIgaWQ9ImdyYWRpZW50Ij48c3RvcCBzdG9wLWNvbG9yPSIjRkYwMDAwIiBzdG9wLW9wYWNpdHk9IjAuMyIgb2Zmc2V0PSIwJSI+PC9zdG9wPjxzdG9wIHN0b3AtY29sb3I9IiNGRjAwMDAiIG9mZnNldD0iMTAwJSI+PC9zdG9wPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxjaXJjbGUgZmlsbD0idXJsKCNncmFkaWVudCkiIGN4PSIxLjUiIGN5PSIxLjUiIHI9IjEuNSI+PC9jaXJjbGU+PC9zdmc+');
  background-position: left calc(100% - 1px);
}
.isApple format-grammar[data-category='grammar'] {
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0cHgiIGhlaWdodD0iM3B4Ij48ZGVmcz48bGluZWFyR3JhZGllbnQgeDE9IjUwJSIgeTE9IjAlIiB4Mj0iNTAlIiB5Mj0iMTAwJSIgaWQ9ImdyYWRpZW50Ij48c3RvcCBzdG9wLWNvbG9yPSIjMDA3QzAwIiBzdG9wLW9wYWNpdHk9IjAuMyIgb2Zmc2V0PSIwJSI+PC9zdG9wPjxzdG9wIHN0b3AtY29sb3I9IiMwMDdDMDAiIG9mZnNldD0iMTAwJSI+PC9zdG9wPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxjaXJjbGUgZmlsbD0idXJsKCNncmFkaWVudCkiIGN4PSIxLjUiIGN5PSIxLjUiIHI9IjEuNSI+PC9jaXJjbGU+PC9zdmc+');
}
.isApple format-grammar[data-category='style'] {
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0cHgiIGhlaWdodD0iM3B4Ij48ZGVmcz48bGluZWFyR3JhZGllbnQgeDE9IjUwJSIgeTE9IjAlIiB4Mj0iNTAlIiB5Mj0iMTAwJSIgaWQ9ImdyYWRpZW50Ij48c3RvcCBzdG9wLWNvbG9yPSIjMDA1REJFIiBzdG9wLW9wYWNpdHk9IjAuMyIgb2Zmc2V0PSIwJSI+PC9zdG9wPjxzdG9wIHN0b3AtY29sb3I9IiMwMDVEQkUiIG9mZnNldD0iMTAwJSI+PC9zdG9wPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxjaXJjbGUgZmlsbD0idXJsKCNncmFkaWVudCkiIGN4PSIxLjUiIGN5PSIxLjUiIHI9IjEuNSI+PC9jaXJjbGU+PC9zdmc+');
}
format-grammar.hovered[data-category='spelling'] {
  background-color: rgba(255, 0, 0, 0.15);
  border-radius: 2px;
}
format-grammar.hovered[data-category='grammar'] {
  background-color: rgba(0, 124, 0, 0.15);
  border-radius: 2px;
}
format-grammar.hovered[data-category='style'] {
  background-color: rgba(0, 92, 190, 0.15);
  border-radius: 2px;
}
