@charset "UTF-8";

@import url('fonts/PTSerif.css');
@import url('fonts/RobotoCondensed.css');

/* latin-ext */
@font-face {
  font-family: 'Outfit';
  font-display: swap;
  font-weight: 100 900;
  src: url(./fonts/Outfit.woff2) format('woff2');
}

@font-face {
  font-family: 'Comic Sans MS';
  font-display: swap;
  src:
    local('Comic Sans MS'),
    url(./fonts/comicsans.woff2) format('woff2');
}

@font-face {
  font-family: ArbutusSlab;
  font-display: swap;
  src:
    local('ArbutusSlab'),
    url(./fonts/ArbutusSlab-Regular.woff2) format('woff2');
}

.font-website-heading {
  font-family: 'ArbutusSlab', serif;
}

.font-general {
  --font-family-text: 'PT Serif', sans-serif;
  --font-family-header: 'PT Serif', serif;
}
.font-sans {
  --font-family-text: 'Alegreya Sans', sans-serif;
  --font-family-header: 'Alegreya', serif;
}
.font-literary {
  --font-family-text: 'Libre Baskerville', sans-serif;
  --font-family-header: 'Libre Baskerville', serif;
}
.font-romance {
  --font-family-text: 'Crimson Text', sans-serif;
  --font-family-header: 'Crimson Text', serif;
}
.font-thriller {
  --font-family-text: 'EB Garamond', sans-serif;
  --font-family-header: 'EB Garamond', serif;
}
.font-dyslexic {
  --font-family-text: 'OpenDyslexic', sans-serif;
  --font-family-header: 'OpenDyslexic', serif;
}
.font-times {
  --font-family-text: 'Times New Roman', Times, serif;
  --font-family-header: 'Times New Roman', Times, serif;
}
.font-courier {
  --font-family-text: 'Courier New', Courier, monospace;
  --font-family-header: 'Courier New', Courier, monospace;
}
.font-comic {
  --font-family-text: 'Comic Sans MS', cursive, sans-serif;
  --font-family-header: 'Comic Sans MS', cursive, sans-serif;
}
.font-screenplay {
  --font-family-text: 'Courier Prime', Courier, monospace;
  --font-family-header: 'Courier Prime', Courier, monospace;
}
.font-screenplay-sans {
  --font-family-text: 'Courier Prime Sans', Courier, monospace;
  --font-family-header: 'Courier Prime Sans', Courier, monospace;
}
.font-handwriting {
  --font-family-text: 'Kalam', sans-serif;
  --font-family-header: 'Kalam', serif;
}
