.editable-content format-comment:not([data-ids='']) {
  background: rgba(252, 202, 52, 0.4);
}
.editable-content format-comment[data-all-ids*=''] {
  background: rgba(252, 202, 52, 1);
  color: black;
}
.editable-content .comment.decoration {
  background: rgb(255, 191, 0);
}
