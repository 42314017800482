.show-author format-author.author-5 {
  background-color: hsla(325, 50%, 50%, 0.25);
}

.show-author format-author.author-1 {
  background-color: hsla(250, 50%, 50%, 0.25);
}

.show-author format-author.author-2 {
  background-color: hsla(175, 25%, 50%, 0.25);
}

.show-author format-author.author-3 {
  background-color: hsla(100, 25%, 50%, 0.25);
}

.show-author format-author.author-4 {
  background-color: hsla(10, 50%, 50%, 0.25);
}

.show-author format-author {
  padding: 4px 0;
  margin: -4px 0;
}
.show-author format-author.default {
  background-color: inherit;
}

@media screen {
  html[data-theme='dark'] .show-author format-author.author-5 {
    background-color: hsl(325, 80%, 50%, 0.25);
  }
  html[data-theme='dark'] .show-author format-author.author-1 {
    background-color: hsl(250, 100%, 80%, 0.25);
  }

  html[data-theme='dark'] .show-author format-author.author-2 {
    background-color: hsl(175, 25%, 50%, 0.25);
  }

  html[data-theme='dark'] .show-author format-author.author-3 {
    background-color: hsl(100, 50%, 50%, 0.25);
  }

  html[data-theme='dark'] .show-author format-author.author-4 {
    background-color: hsl(10, 75%, 75%, 0.25);
  }
}
