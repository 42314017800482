:root {
  /*
    By using CSS variables for the grid, the variables can be updated as needed for a container and the calculations for
    the grid will be updated accordingly. The CSS won't need to be defined for each size. The following 3 variables are
    the only ones you need to change. Grids can have up to 12 columns.
  */
  --layout-margin: 16px;
  --layout-gutter: 16px;
  --layout-columns: 4;
  --layout-cross-margin: var(--layout-margin);
  --layout-cross-gutter: var(--layout-gutter);
}

.layout {
  display: grid;
  box-sizing: border-box;
  grid-column-gap: var(--layout-gutter);
  column-gap: var(--layout-gutter);
  grid-row-gap: var(--layout-cross-gutter);
  row-gap: var(--layout-cross-gutter);
  grid-template-columns: repeat(var(--layout-columns), minmax(0, 1fr));
  padding: var(--layout-cross-margin) var(--layout-margin);
}
.layout.vertical {
  grid-auto-flow: column;
  grid-column-gap: var(--layout-cross-gutter);
  column-gap: var(--layout-cross-gutter);
  grid-row-gap: var(--layout-gutter);
  row-gap: var(--layout-gutter);
  grid-template-columns: auto;
  grid-template-rows: repeat(var(--layout-columns), minmax(0, 1fr));
  padding: var(--layout-margin) var(--layout-cross-margin);
}
.layout.align-start,
.layout.vertical.justify-start {
  margin-left: 0;
  margin-right: auto;
}
.layout.align-end,
.layout.vertical.justify-end {
  margin-left: auto;
  margin-right: 0;
}
.layout.align-center,
.layout.vertical.justify-center {
  margin-left: auto;
  margin-right: auto;
}
/* Requires the parent to be display: flex */
.layout.vertical.align-start,
.layout.justify-start {
  margin-top: 0;
  margin-bottom: auto;
}
.layout.vertical.align-end,
.layout.justify-end {
  margin-top: auto;
  margin-bottom: 0;
}
.layout.vertical.align-center,
.layout.justify-center {
  margin-top: auto;
  margin-bottom: auto;
}

.layout > .span-1,
.layout.vertical .span-cross-1 {
  grid-column-end: span 1;
}
.layout > .span-2,
.layout.vertical .span-cross-2 {
  grid-column-end: span 2;
}
.layout > .span-3,
.layout.vertical .span-cross-3 {
  grid-column-end: span 3;
}
.layout > .span-4,
.layout.vertical .span-cross-4 {
  grid-column-end: span 4;
}
.layout > .span-5,
.layout.vertical .span-cross-5 {
  grid-column-end: span 5;
}
.layout > .span-6,
.layout.vertical .span-cross-6 {
  grid-column-end: span 6;
}
.layout > .span-7,
.layout.vertical .span-cross-7 {
  grid-column-end: span 7;
}
.layout > .span-8,
.layout.vertical .span-cross-8 {
  grid-column-end: span 8;
}
.layout > .span-9,
.layout.vertical .span-cross-9 {
  grid-column-end: span 9;
}
.layout > .span-10,
.layout.vertical .span-cross-10 {
  grid-column-end: span 10;
}
.layout > .span-11,
.layout.vertical .span-cross-11 {
  grid-column-end: span 11;
}
.layout > .span-12,
.layout.vertical .span-cross-12 {
  grid-column-end: span 12;
}
.layout > .span-cross-1,
.layout.vertical .span-1 {
  grid-row-end: span 1;
}
.layout > .span-cross-2,
.layout.vertical .span-2 {
  grid-row-end: span 2;
}
.layout > .span-cross-3,
.layout.vertical .span-3 {
  grid-row-end: span 3;
}
.layout > .span-cross-4,
.layout.vertical .span-4 {
  grid-row-end: span 4;
}
.layout > .span-cross-5,
.layout.vertical .span-5 {
  grid-row-end: span 5;
}
.layout > .span-cross-6,
.layout.vertical .span-6 {
  grid-row-end: span 6;
}
.layout > .span-cross-7,
.layout.vertical .span-7 {
  grid-row-end: span 7;
}
.layout > .span-cross-8,
.layout.vertical .span-8 {
  grid-row-end: span 8;
}
.layout > .span-cross-9,
.layout.vertical .span-9 {
  grid-row-end: span 9;
}
.layout > .span-cross-10,
.layout.vertical .span-10 {
  grid-row-end: span 10;
}
.layout > .span-cross-11,
.layout.vertical .span-11 {
  grid-row-end: span 11;
}
.layout > .span-cross-12,
.layout.vertical .span-12 {
  grid-row-end: span 12;
}

/* Nested layouts of the same direction fit in their parents’ grid */
.layout:not(.vertical) .layout:not(.vertical),
.layout.vertical .layout.vertical {
  --layout-margin: 0;
  --layout-cross-margin: 0;
}
.layout:not(.vertical) > .span-1 .layout:not(.vertical),
.layout.vertial > .span-1 .layout.vertical {
  --layout-columns: 1;
}
.layout:not(.vertical) > .span-2 .layout:not(.vertical),
.layout.vertial > .span-2 .layout.vertical {
  --layout-columns: 2;
}
.layout:not(.vertical) > .span-3 .layout:not(.vertical),
.layout.vertial > .span-3 .layout.vertical {
  --layout-columns: 3;
}
.layout:not(.vertical) > .span-4 .layout:not(.vertical),
.layout.vertial > .span-4 .layout.vertical {
  --layout-columns: 4;
}
.layout:not(.vertical) > .span-5 .layout:not(.vertical),
.layout.vertial > .span-5 .layout.vertical {
  --layout-columns: 5;
}
.layout:not(.vertical) > .span-6 .layout:not(.vertical),
.layout.vertial > .span-6 .layout.vertical {
  --layout-columns: 6;
}
.layout:not(.vertical) > .span-7 .layout:not(.vertical),
.layout.vertial > .span-7 .layout.vertical {
  --layout-columns: 7;
}
.layout:not(.vertical) > .span-8 .layout:not(.vertical),
.layout.vertial > .span-8 .layout.vertical {
  --layout-columns: 8;
}
.layout:not(.vertical) > .span-9 .layout:not(.vertical),
.layout.vertial > .span-9 .layout.vertical {
  --layout-columns: 9;
}
.layout:not(.vertical) > .span-10 .layout:not(.vertical),
.layout.vertial > .span-10 .layout.vertical {
  --layout-columns: 10;
}
.layout:not(.vertical) > .span-11 .layout:not(.vertical),
.layout.vertial > .span-11 .layout.vertical {
  --layout-columns: 11;
}
.layout:not(.vertical) > .span-12 .layout:not(.vertical),
.layout.vertial > .span-12 .layout.vertical {
  --layout-columns: 12;
}

.layout > .justify-start {
  justify-self: start;
}
.layout > .justify-center {
  justify-self: center;
}
.layout > .justify-end {
  justify-self: end;
}
.layout > .align-start {
  align-self: start;
}
.layout > .align-center {
  align-self: center;
}
.layout > .align-end {
  align-self: end;
}

.layout > .order-1 {
  order: 1;
}
.layout > .order-2 {
  order: 2;
}
.layout > .order-3 {
  order: 3;
}
.layout > .order-4 {
  order: 4;
}
.layout > .order-5 {
  order: 5;
}
.layout > .order-6 {
  order: 6;
}
.layout > .order-7 {
  order: 7;
}
.layout > .order-8 {
  order: 8;
}
.layout > .order-9 {
  order: 9;
}
.layout > .order-10 {
  order: 10;
}
.layout > .order-11 {
  order: 11;
}
.layout > .order-12 {
  order: 12;
}

@media (max-width: 599.98px) {
  .layout > .span-1,
  .layout.vertical .span-cross-1 {
    grid-column-end: span 1;
  }
  .layout > .span-2,
  .layout.vertical .span-cross-2 {
    grid-column-end: span 1;
  }
  .layout > .span-3,
  .layout.vertical .span-cross-3 {
    grid-column-end: span 1;
  }
  .layout > .span-4,
  .layout.vertical .span-cross-4 {
    grid-column-end: span 2;
  }
  .layout > .span-5,
  .layout.vertical .span-cross-5 {
    grid-column-end: span 2;
  }
  .layout > .span-6,
  .layout.vertical .span-cross-6 {
    grid-column-end: span 2;
  }
  .layout > .span-7,
  .layout.vertical .span-cross-7 {
    grid-column-end: span 3;
  }
  .layout > .span-8,
  .layout.vertical .span-cross-8 {
    grid-column-end: span 3;
  }
  .layout > .span-9,
  .layout.vertical .span-cross-9 {
    grid-column-end: span 3;
  }
  .layout > .span-10,
  .layout.vertical .span-cross-10 {
    grid-column-end: span 4;
  }
  .layout > .span-11,
  .layout.vertical .span-cross-11 {
    grid-column-end: span 4;
  }
  .layout > .span-12,
  .layout.vertical .span-cross-12 {
    grid-column-end: span 4;
  }

  .layout > .span-1-mobile,
  .layout.vertical .span-cross-1-mobile,
  .layout.vertical .span-cross-1-mobile {
    grid-column-end: span 1;
  }
  .layout > .span-2-mobile,
  .layout.vertical .span-cross-2-mobile {
    grid-column-end: span 2;
  }
  .layout > .span-3-mobile,
  .layout.vertical .span-cross-3-mobile {
    grid-column-end: span 3;
  }
  .layout > .span-4-mobile,
  .layout.vertical .span-cross-4-mobile {
    grid-column-end: span 4;
  }
  .layout > .span-cross-1-mobile,
  .layout.vertical .span-1-mobile {
    grid-row-end: span 1;
  }
  .layout > .span-cross-2-mobile,
  .layout.vertical .span-2-mobile {
    grid-row-end: span 2;
  }
  .layout > .span-cross-3-mobile,
  .layout.vertical .span-3-mobile {
    grid-row-end: span 3;
  }
  .layout > .span-cross-4-mobile,
  .layout.vertical .span-4-mobile {
    grid-row-end: span 4;
  }

  .layout:not(.vertical) > .span-1-mobile .layout:not(.vertical),
  .layout.vertial > .span-1-mobile .layout.vertical {
    --layout-columns: 1;
  }
  .layout:not(.vertical) > .span-2-mobile .layout:not(.vertical),
  .layout.vertial > .span-2-mobile .layout.vertical {
    --layout-columns: 2;
  }
  .layout:not(.vertical) > .span-3-mobile .layout:not(.vertical),
  .layout.vertial > .span-3-mobile .layout.vertical {
    --layout-columns: 3;
  }
  .layout:not(.vertical) > .span-4-mobile .layout:not(.vertical),
  .layout.vertial > .span-4-mobile .layout.vertical {
    --layout-columns: 4;
  }

  .layout > .justify-start-mobile {
    justify-self: start;
  }
  .layout > .justify-center-mobile {
    justify-self: center;
  }
  .layout > .justify-end-mobile {
    justify-self: end;
  }
  .layout > .align-start-mobile {
    align-self: start;
  }
  .layout > .align-center-mobile {
    align-self: center;
  }
  .layout > .align-end-mobile {
    align-self: end;
  }

  .layout > .order-1-mobile {
    order: 1;
  }
  .layout > .order-2-mobile {
    order: 2;
  }
  .layout > .order-3-mobile {
    order: 3;
  }
  .layout > .order-4-mobile {
    order: 4;
  }
  .layout > .order-5-mobile {
    order: 5;
  }
  .layout > .order-6-mobile {
    order: 6;
  }
  .layout > .order-7-mobile {
    order: 7;
  }
  .layout > .order-8-mobile {
    order: 8;
  }
  .layout > .order-9-mobile {
    order: 9;
  }
  .layout > .order-10-mobile {
    order: 10;
  }
  .layout > .order-11-mobile {
    order: 11;
  }
  .layout > .order-12-mobile {
    order: 12;
  }
}

@media (min-width: 600px) and (max-width: 839.98px) {
  :root {
    --layout-columns: 8;
  }
  .layout > .span-1,
  .layout.vertical .span-cross-1 {
    grid-column-end: span 1;
  }
  .layout > .span-2,
  .layout.vertical .span-cross-2 {
    grid-column-end: span 1;
  }
  .layout > .span-3,
  .layout.vertical .span-cross-3 {
    grid-column-end: span 2;
  }
  .layout > .span-4,
  .layout.vertical .span-cross-4 {
    grid-column-end: span 3;
  }
  .layout > .span-5,
  .layout.vertical .span-cross-5 {
    grid-column-end: span 3;
  }
  .layout > .span-6,
  .layout.vertical .span-cross-6 {
    grid-column-end: span 4;
  }
  .layout > .span-7,
  .layout.vertical .span-cross-7 {
    grid-column-end: span 5;
  }
  .layout > .span-8,
  .layout.vertical .span-cross-8 {
    grid-column-end: span 5;
  }
  .layout > .span-9,
  .layout.vertical .span-cross-9 {
    grid-column-end: span 6;
  }
  .layout > .span-10,
  .layout.vertical .span-cross-10 {
    grid-column-end: span 7;
  }
  .layout > .span-11,
  .layout.vertical .span-cross-11 {
    grid-column-end: span 7;
  }
  .layout > .span-12,
  .layout.vertical .span-cross-12 {
    grid-column-end: span 8;
  }

  .layout > .span-1-tablet,
  .layout.vertical .span-cross-1-tablet {
    grid-column-end: span 1;
  }
  .layout > .span-2-tablet,
  .layout.vertical .span-cross-2-tablet {
    grid-column-end: span 2;
  }
  .layout > .span-3-tablet,
  .layout.vertical .span-cross-3-tablet {
    grid-column-end: span 3;
  }
  .layout > .span-4-tablet,
  .layout.vertical .span-cross-4-tablet {
    grid-column-end: span 4;
  }
  .layout > .span-5-tablet,
  .layout.vertical .span-cross-5-tablet {
    grid-column-end: span 5;
  }
  .layout > .span-6-tablet,
  .layout.vertical .span-cross-6-tablet {
    grid-column-end: span 6;
  }
  .layout > .span-7-tablet,
  .layout.vertical .span-cross-7-tablet {
    grid-column-end: span 7;
  }
  .layout > .span-8-tablet,
  .layout.vertical .span-cross-8-tablet {
    grid-column-end: span 8;
  }
  .layout > .span-cross-1-tablet,
  .layout.vertical .span-1-tablet {
    grid-row-end: span 1;
  }
  .layout > .span-cross-2-tablet,
  .layout.vertical .span-2-tablet {
    grid-row-end: span 2;
  }
  .layout > .span-cross-3-tablet,
  .layout.vertical .span-3-tablet {
    grid-row-end: span 3;
  }
  .layout > .span-cross-4-tablet,
  .layout.vertical .span-4-tablet {
    grid-row-end: span 4;
  }
  .layout > .span-cross-5-tablet,
  .layout.vertical .span-5-tablet {
    grid-row-end: span 5;
  }
  .layout > .span-cross-6-tablet,
  .layout.vertical .span-6-tablet {
    grid-row-end: span 6;
  }
  .layout > .span-cross-7-tablet,
  .layout.vertical .span-7-tablet {
    grid-row-end: span 7;
  }
  .layout > .span-cross-8-tablet,
  .layout.vertical .span-8-tablet {
    grid-row-end: span 8;
  }

  .layout:not(.vertical) > .span-1-tablet .layout:not(.vertical),
  .layout.vertial > .span-1-tablet .layout.vertical {
    --layout-columns: 1;
  }
  .layout:not(.vertical) > .span-2-tablet .layout:not(.vertical),
  .layout.vertial > .span-2-tablet .layout.vertical {
    --layout-columns: 2;
  }
  .layout:not(.vertical) > .span-3-tablet .layout:not(.vertical),
  .layout.vertial > .span-3-tablet .layout.vertical {
    --layout-columns: 3;
  }
  .layout:not(.vertical) > .span-4-tablet .layout:not(.vertical),
  .layout.vertial > .span-4-tablet .layout.vertical {
    --layout-columns: 4;
  }
  .layout:not(.vertical) > .span-5-tablet .layout:not(.vertical),
  .layout.vertial > .span-5-tablet .layout.vertical {
    --layout-columns: 5;
  }
  .layout:not(.vertical) > .span-6-tablet .layout:not(.vertical),
  .layout.vertial > .span-6-tablet .layout.vertical {
    --layout-columns: 6;
  }
  .layout:not(.vertical) > .span-7-tablet .layout:not(.vertical),
  .layout.vertial > .span-7-tablet .layout.vertical {
    --layout-columns: 7;
  }
  .layout:not(.vertical) > .span-8-tablet .layout:not(.vertical),
  .layout.vertial > .span-8-tablet .layout.vertical {
    --layout-columns: 8;
  }

  .layout > .justify-start-tablet {
    justify-self: start;
  }
  .layout > .justify-center-tablet {
    justify-self: center;
  }
  .layout > .justify-end-tablet {
    justify-self: end;
  }
  .layout > .align-start-tablet {
    align-self: start;
  }
  .layout > .align-center-tablet {
    align-self: center;
  }
  .layout > .align-end-tablet {
    align-self: end;
  }

  .layout > .order-1-tablet {
    order: 1;
  }
  .layout > .order-2-tablet {
    order: 2;
  }
  .layout > .order-3-tablet {
    order: 3;
  }
  .layout > .order-4-tablet {
    order: 4;
  }
  .layout > .order-5-tablet {
    order: 5;
  }
  .layout > .order-6-tablet {
    order: 6;
  }
  .layout > .order-7-tablet {
    order: 7;
  }
  .layout > .order-8-tablet {
    order: 8;
  }
  .layout > .order-9-tablet {
    order: 9;
  }
  .layout > .order-10-tablet {
    order: 10;
  }
  .layout > .order-11-tablet {
    order: 11;
  }
  .layout > .order-12-tablet {
    order: 12;
  }
}

@media (min-width: 720px) {
  :root {
    --layout-margin: 24px;
    --layout-gutter: 24px;
  }
}

@media (min-width: 840px) {
  :root {
    --layout-columns: 12;
  }
  .layout > .span-1-desktop,
  .layout.vertical .span-cross-1-desktop {
    grid-column-end: span 1;
  }
  .layout > .span-2-desktop,
  .layout.vertical .span-cross-2-desktop {
    grid-column-end: span 2;
  }
  .layout > .span-3-desktop,
  .layout.vertical .span-cross-3-desktop {
    grid-column-end: span 3;
  }
  .layout > .span-4-desktop,
  .layout.vertical .span-cross-4-desktop {
    grid-column-end: span 4;
  }
  .layout > .span-5-desktop,
  .layout.vertical .span-cross-5-desktop {
    grid-column-end: span 5;
  }
  .layout > .span-6-desktop,
  .layout.vertical .span-cross-6-desktop {
    grid-column-end: span 6;
  }
  .layout > .span-7-desktop,
  .layout.vertical .span-cross-7-desktop {
    grid-column-end: span 7;
  }
  .layout > .span-8-desktop,
  .layout.vertical .span-cross-8-desktop {
    grid-column-end: span 8;
  }
  .layout > .span-9-desktop,
  .layout.vertical .span-cross-9-desktop {
    grid-column-end: span 9;
  }
  .layout > .span-10-desktop,
  .layout.vertical .span-cross-10-desktop {
    grid-column-end: span 10;
  }
  .layout > .span-11-desktop,
  .layout.vertical .span-cross-11-desktop {
    grid-column-end: span 11;
  }
  .layout > .span-12-desktop,
  .layout.vertical .span-cross-12-desktop {
    grid-column-end: span 12;
  }
  .layout > .span-cross-1-desktop,
  .layout.vertical .span-1-desktop {
    grid-row-end: span 1;
  }
  .layout > .span-cross-2-desktop,
  .layout.vertical .span-2-desktop {
    grid-row-end: span 2;
  }
  .layout > .span-cross-3-desktop,
  .layout.vertical .span-3-desktop {
    grid-row-end: span 3;
  }
  .layout > .span-cross-4-desktop,
  .layout.vertical .span-4-desktop {
    grid-row-end: span 4;
  }
  .layout > .span-cross-5-desktop,
  .layout.vertical .span-5-desktop {
    grid-row-end: span 5;
  }
  .layout > .span-cross-6-desktop,
  .layout.vertical .span-6-desktop {
    grid-row-end: span 6;
  }
  .layout > .span-cross-7-desktop,
  .layout.vertical .span-7-desktop {
    grid-row-end: span 7;
  }
  .layout > .span-cross-8-desktop,
  .layout.vertical .span-8-desktop {
    grid-row-end: span 8;
  }
  .layout > .span-cross-9-desktop,
  .layout.vertical .span-9-desktop {
    grid-row-end: span 9;
  }
  .layout > .span-cross-10-desktop,
  .layout.vertical .span-10-desktop {
    grid-row-end: span 10;
  }
  .layout > .span-cross-11-desktop,
  .layout.vertical .span-11-desktop {
    grid-row-end: span 11;
  }
  .layout > .span-cross-12-desktop,
  .layout.vertical .span-12-desktop {
    grid-row-end: span 12;
  }

  .layout:not(.vertical) > .span-1-desktop .layout:not(.vertical),
  .layout.vertial > .span-1-desktop .layout.vertical {
    --layout-columns: 1;
  }
  .layout:not(.vertical) > .span-2-desktop .layout:not(.vertical),
  .layout.vertial > .span-2-desktop .layout.vertical {
    --layout-columns: 2;
  }
  .layout:not(.vertical) > .span-3-desktop .layout:not(.vertical),
  .layout.vertial > .span-3-desktop .layout.vertical {
    --layout-columns: 3;
  }
  .layout:not(.vertical) > .span-4-desktop .layout:not(.vertical),
  .layout.vertial > .span-4-desktop .layout.vertical {
    --layout-columns: 4;
  }
  .layout:not(.vertical) > .span-5-desktop .layout:not(.vertical),
  .layout.vertial > .span-5-desktop .layout.vertical {
    --layout-columns: 5;
  }
  .layout:not(.vertical) > .span-6-desktop .layout:not(.vertical),
  .layout.vertial > .span-6-desktop .layout.vertical {
    --layout-columns: 6;
  }
  .layout:not(.vertical) > .span-7-desktop .layout:not(.vertical),
  .layout.vertial > .span-7-desktop .layout.vertical {
    --layout-columns: 7;
  }
  .layout:not(.vertical) > .span-8-desktop .layout:not(.vertical),
  .layout.vertial > .span-8-desktop .layout.vertical {
    --layout-columns: 8;
  }
  .layout:not(.vertical) > .span-9-desktop .layout:not(.vertical),
  .layout.vertial > .span-9-desktop .layout.vertical {
    --layout-columns: 9;
  }
  .layout:not(.vertical) > .span-10-desktop .layout:not(.vertical),
  .layout.vertial > .span-10-desktop .layout.vertical {
    --layout-columns: 10;
  }
  .layout:not(.vertical) > .span-11-desktop .layout:not(.vertical),
  .layout.vertial > .span-11-desktop .layout.vertical {
    --layout-columns: 11;
  }
  .layout:not(.vertical) > .span-12-desktop .layout:not(.vertical),
  .layout.vertial > .span-12-desktop .layout.vertical {
    --layout-columns: 12;
  }

  .layout > .justify-start-desktop {
    justify-self: start;
  }
  .layout > .justify-center-desktop {
    justify-self: center;
  }
  .layout > .justify-end-desktop {
    justify-self: end;
  }
  .layout > .align-start-desktop {
    align-self: start;
  }
  .layout > .align-center-desktop {
    align-self: center;
  }
  .layout > .align-end-desktop {
    align-self: end;
  }

  .layout > .order-1-desktop {
    order: 1;
  }
  .layout > .order-2-desktop {
    order: 2;
  }
  .layout > .order-3-desktop {
    order: 3;
  }
  .layout > .order-4-desktop {
    order: 4;
  }
  .layout > .order-5-desktop {
    order: 5;
  }
  .layout > .order-6-desktop {
    order: 6;
  }
  .layout > .order-7-desktop {
    order: 7;
  }
  .layout > .order-8-desktop {
    order: 8;
  }
  .layout > .order-9-desktop {
    order: 9;
  }
  .layout > .order-10-desktop {
    order: 10;
  }
  .layout > .order-11-desktop {
    order: 11;
  }
  .layout > .order-12-desktop {
    order: 12;
  }
}
