* {
  box-sizing: border-box;
}

body {
  background-color: var(--background);
  color: var(--text-color-normal);
  -webkit-overflow-scrolling: auto;
}
/* body {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: hidden;
} */

body a {
  cursor: pointer;
}
body a[disabled],
body a.disabled {
  cursor: default;
  opacity: 0.5;
  pointer-events: none;
}
body.loading {
  background-color: #fff;
}

[contenteditable]:focus {
  outline: none;
}
body.ready .not-found {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
body.ready .not-found:before {
  content: 'Not Found';
  font-size: 5rem;
  margin-bottom: 5rem;
  color: #bbb;
  text-shadow:
    0 1px 1px rgba(255, 255, 255, 0.5),
    0 -1px 1px rgba(0, 0, 0, 0.4);
  border-bottom: 4px solid #bbb;
  box-shadow:
    0 1px 0 rgba(255, 255, 255, 0.3),
    inset 0 -1px 0 rgba(0, 0, 0, 0.2);
}

::-webkit-scrollbar {
  width: 14px;
  height: 14px;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  min-height: 80px;
  min-width: 80px;
}
::-webkit-scrollbar-track:window-inactive {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.075);
}
::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(0, 0, 0, 0.02);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
}

@keyframes placeHolderShimmer {
  from {
    background-position: -500px 0;
  }
  to {
    background-position: 500px 0;
  }
}
.loading-placeholder {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #eee 8%, #ddd 18%, #eee 33%);
  background-size: 800px 104px;
  position: relative;
  height: 8px;
  margin-bottom: 6px;
}

.focus-fade {
  transition: opacity 0.5s ease-out;
}
.focus-slowly .focus-fade {
  transition: opacity 3s ease-out;
}
.focusing .focus-fade {
  opacity: 0;
  pointer-events: none;
}

.focusing.focus-slowly .focus-scroll::-webkit-scrollbar,
.focusing.focus-slowly .focus-scroll::-webkit-scrollbar-track,
.focusing.focus-slowly .focus-scroll::-webkit-scrollbar-thumb,
.focusing:not(.focused) .focus-scroll::-webkit-scrollbar,
.focusing:not(.focused) .focus-scroll::-webkit-scrollbar-track,
.focusing:not(.focused) .focus-scroll::-webkit-scrollbar-thumb {
  background: transparent;
  box-shadow: none;
  scrollbar-color: transparent transparent;
}
