a {
  color: var(--link-color);
  text-decoration: none;
}
a:hover {
  color: var(--link-hover-color);
  text-decoration: underline;
}
hr {
  margin: 1rem 0;
  border: 0;
  border-top: 1px solid var(--gray-lighter);
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0.5em;
  font-weight: 500;
  line-height: normal;
}
h1 {
  font-size: 2.5rem;
}
h2 {
  font-size: 2rem;
}
h3 {
  font-size: 1.75rem;
}
h4 {
  font-size: 1.5rem;
}
h5 {
  font-size: 1.25rem;
}
h6 {
  font-size: 1rem;
}
.pull-right {
  float: right;
}
.pull-left {
  float: left;
}
.fade {
  animation: fade 0.15s linear;
}
.btn {
  display: inline-block;
  font-weight: normal;
  line-height: 1.25;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  cursor: pointer;
  outline: 0;
  transition: all 0.2s ease-in-out;
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  font-weight: 500;
  font-family: var(--font-family-ui);
  text-transform: uppercase;
  border-radius: 0.25rem;
}
.btn:hover,
.btn:focus {
  text-decoration: none;
}
.btn:focus,
.btn.focus {
  outline: 0;
  box-shadow: 0 0 0 2px rgba(55, 114, 255, 0.25);
}
.btn.disabled,
.btn:disabled {
  cursor: default;
  opacity: 0.65;
  box-shadow: none;
}
.btn:active,
.btn.active {
  background-image: none;
  box-shadow:
    0 0 0 2px rgba(55, 114, 255, 0.25),
    inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.btn:hover,
.btn:focus {
  text-decoration: none;
}
.btn:focus {
  background-color: #e6e6e6;
  border-color: #8c8c8c;
}
.btn:hover {
  background-color: #e6e6e6;
  border-color: #adadad;
}
.btn:active,
.btn.active {
  background-image: none;
  box-shadow: inset 0 3px 6px rgba(0, 0, 0, 0.15);
  background-color: #d4d4d4;
  border-color: #8c8c8c;
}
.btn.active {
  background-color: #d4d4d4;
  border-color: #8c8c8c;
}
.btn[disabled],
.btn[disabled]:hover,
.btn.disabled,
.btn.disabled:hover {
  cursor: default;
  opacity: 0.5;
  background-color: #fff;
  border-color: #ccc;
}
.btn.text {
  display: flex;
  align-items: center;
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  text-transform: none;
  font-size: 14px;
  outline: none;
  cursor: pointer;
  color: var(--text-color-lighter);
  text-shadow: var(--text-highlight);
  box-shadow: none;
}
.btn.text:not([disabled]):hover,
.btn.text:not([disabled]):focus,
.btn.text:not(.disabled):hover,
.btn.text:not(.disabled):focus {
  color: var(--text-color-normal);
}
.btn.text .icon {
  font-size: 16px;
  margin-right: 8px;
}
.btn.primary {
  color: #fff;
  background-color: rgb(var(--dabble-blue-500));
  border-color: rgb(var(--dabble-blue-600));
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
}
.btn.primary:hover {
  color: #fff;
  background-color: rgb(var(--dabble-blue-600));
  border-color: rgb(var(--dabble-blue-700));
}
.btn.primary:focus,
.btn.primary.focus {
  box-shadow: 0 0 0 2px rgba(43, 140, 151, 0.5);
}
.btn.primary.disabled,
.btn.primary:disabled {
  background-color: rgb(var(--dabble-blue-500));
  border-color: rgb(var(--dabble-blue-600));
}
.btn.primary:active,
.btn.primary.active,
.show > .btn.primary.dropdown-toggle {
  color: #fff;
  background-color: rgb(var(--dabble-blue-600));
  background-image: none;
  border-color: rgb(var(--dabble-blue-700));
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.btn.secondary {
  color: #292b2c;
  background-color: #fff;
  border-color: #ccc;
}
.btn.secondary:hover {
  color: #292b2c;
  background-color: #e6e6e6;
  border-color: #adadad;
}
.btn.secondary:focus,
.btn.secondary.focus {
  box-shadow: 0 0 0 2px rgba(204, 204, 204, 0.5);
}
.btn.secondary.disabled,
.btn.secondary:disabled {
  background-color: #fff;
  border-color: #ccc;
}
.btn.secondary:active,
.btn.secondary.active,
.show > .btn.secondary.dropdown-toggle {
  color: #292b2c;
  background-color: #e6e6e6;
  background-image: none;
  border-color: #adadad;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.btn.darker {
  color: #292b2c;
  background-color: #eee;
  border-color: #aaa;
}
.btn.info {
  color: #fff;
  background-color: #5bc0de;
  border-color: #31b0d5;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
}
.btn.info:hover {
  color: #fff;
  background-color: #31b0d5;
  border-color: #2289a7;
}
.btn.info:focus,
.btn.info.focus {
  box-shadow: 0 0 0 2px rgba(49, 176, 213, 0.5);
}
.btn.info.disabled,
.btn.info:disabled {
  background-color: #5bc0de;
  border-color: #31b0d5;
}
.btn.info:active,
.btn.info.active,
.show > .btn.info.dropdown-toggle {
  color: #fff;
  background-color: #31b0d5;
  background-image: none;
  border-color: #2289a7;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.btn.success {
  color: #fff;
  background-color: #5cb85c;
  border-color: #449d44;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
}
.btn.success:hover {
  color: #fff;
  background-color: #449d44;
  border-color: #327232;
}
.btn.success:focus,
.btn.success.focus {
  box-shadow: 0 0 0 2px rgba(68, 157, 68, 0.5);
}
.btn.success.disabled,
.btn.success:disabled {
  background-color: #5cb85c;
  border-color: #449d44;
}
.btn.success:active,
.btn.success.active,
.show > .btn.success.dropdown-toggle {
  color: #fff;
  background-color: #449d44;
  background-image: none;
  border-color: #327232;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.btn.warning {
  color: #fff;
  background-color: #f0ad4e;
  border-color: #ec971f;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
}
.btn.warning:hover {
  color: #fff;
  background-color: #ec971f;
  border-color: #be7610;
}
.btn.warning:focus,
.btn.warning.focus {
  box-shadow: 0 0 0 2px rgba(236, 151, 31, 0.5);
}
.btn.warning.disabled,
.btn.warning:disabled {
  background-color: #f0ad4e;
  border-color: #ec971f;
}
.btn.warning:active,
.btn.warning.active,
.show > .btn.warning.dropdown-toggle {
  color: #fff;
  background-color: #ec971f;
  background-image: none;
  border-color: #be7610;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.btn.danger,
.danger .btn.primary {
  color: #fff;
  background-color: #d9534f;
  border-color: #c9302c;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
}
.btn.danger:hover,
.danger .btn.primary:hover {
  color: #fff;
  background-color: #c9302c;
  border-color: #972421;
}
.btn.danger:focus,
.btn.danger.focus,
.danger .btn.primary:focus,
.danger .btn.primary.focus {
  box-shadow: 0 0 0 2px rgba(201, 48, 44, 0.5);
}
.btn.danger.disabled,
.btn.danger:disabled,
.danger .btn.primary.disabled,
.danger .btn.primary:disabled {
  background-color: #d9534f;
  border-color: #c9302c;
}
.btn.danger:active,
.btn.danger.active,
.show > .btn.danger.dropdown-toggle,
.danger .btn.primary:active,
.danger .btn.primary.active {
  color: #fff;
  background-color: #c9302c;
  background-image: none;
  border-color: #972421;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.btn.outline.primary {
  color: rgb(var(--dabble-blue-500));
  background-image: none;
  background-color: transparent;
  border-color: rgb(var(--dabble-blue-500));
  text-shadow: none;
}
.btn.outline.primary:hover {
  color: #fff;
  background-color: rgb(var(--dabble-blue-500));
  border-color: rgb(var(--dabble-blue-500));
}
.btn.outline.primary:focus,
.btn.outline.primary.focus {
  box-shadow: 0 0 0 2px rgba(54, 177, 191, 0.5);
}
.btn.outline.primary.disabled,
.btn.outline.primary:disabled {
  color: rgb(var(--dabble-blue-500));
  background-color: transparent;
}
.btn.outline.primary:active,
.btn.outline.primary.active,
.show > .btn.outline.primary.dropdown-toggle {
  color: #fff;
  background-color: rgb(var(--dabble-blue-500));
  border-color: rgb(var(--dabble-blue-500));
}
.btn.outline.secondary {
  color: #ccc;
  background-image: none;
  background-color: transparent;
  border-color: #ccc;
  text-shadow: none;
}
.btn.outline.secondary:hover {
  color: #fff;
  background-color: #ccc;
  border-color: #ccc;
}
.btn.outline.secondary:focus,
.btn.outline.secondary.focus {
  box-shadow: 0 0 0 2px rgba(204, 204, 204, 0.5);
}
.btn.outline.secondary.disabled,
.btn.outline.secondary:disabled {
  color: #ccc;
  background-color: transparent;
}
.btn.outline.secondary:active,
.btn.outline.secondary.active,
.show > .btn.outline.secondary.dropdown-toggle {
  color: #fff;
  background-color: #ccc;
  border-color: #ccc;
}
.btn.outline.info {
  color: #5bc0de;
  background-image: none;
  background-color: transparent;
  border-color: #5bc0de;
  text-shadow: none;
}
.btn.outline.info:hover {
  color: #fff;
  background-color: #5bc0de;
  border-color: #5bc0de;
}
.btn.outline.info:focus,
.btn.outline.info.focus {
  box-shadow: 0 0 0 2px rgba(91, 192, 222, 0.5);
}
.btn.outline.info.disabled,
.btn.outline.info:disabled {
  color: #5bc0de;
  background-color: transparent;
}
.btn.outline.info:active,
.btn.outline.info.active,
.show > .btn.outline.info.dropdown-toggle {
  color: #fff;
  background-color: #5bc0de;
  border-color: #5bc0de;
}
.btn.outline.success {
  color: #5cb85c;
  background-image: none;
  background-color: transparent;
  border-color: #5cb85c;
  text-shadow: none;
}
.btn.outline.success:hover {
  color: #fff;
  background-color: #5cb85c;
  border-color: #5cb85c;
}
.btn.outline.success:focus,
.btn.outline.success.focus {
  box-shadow: 0 0 0 2px rgba(92, 184, 92, 0.5);
}
.btn.outline.success.disabled,
.btn.outline.success:disabled {
  color: #5cb85c;
  background-color: transparent;
}
.btn.outline.success:active,
.btn.outline.success.active,
.show > .btn.outline.success.dropdown-toggle {
  color: #fff;
  background-color: #5cb85c;
  border-color: #5cb85c;
}
.btn.outline.warning {
  color: #f0ad4e;
  background-image: none;
  background-color: transparent;
  border-color: #f0ad4e;
  text-shadow: none;
}
.btn.outline.warning:hover {
  color: #fff;
  background-color: #f0ad4e;
  border-color: #f0ad4e;
}
.btn.outline.warning:focus,
.btn.outline.warning.focus {
  box-shadow: 0 0 0 2px rgba(240, 173, 78, 0.5);
}
.btn.outline.warning.disabled,
.btn.outline.warning:disabled {
  color: #f0ad4e;
  background-color: transparent;
}
.btn.outline.warning:active,
.btn.outline.warning.active,
.show > .btn.outline.warning.dropdown-toggle {
  color: #fff;
  background-color: #f0ad4e;
  border-color: #f0ad4e;
}
.btn.outline.danger {
  color: #d9534f;
  background-image: none;
  background-color: transparent;
  border-color: #d9534f;
  text-shadow: none;
}
.btn.outline.danger:hover {
  color: #fff;
  background-color: #d9534f;
  border-color: #d9534f;
}
.btn.outline.danger:focus,
.btn.outline.danger.focus {
  box-shadow: 0 0 0 2px rgba(217, 83, 79, 0.5);
}
.btn.outline.danger.disabled,
.btn.outline.danger:disabled {
  color: #d9534f;
  background-color: transparent;
}
.btn.outline.danger:active,
.btn.outline.danger.active,
.show > .btn.outline.danger.dropdown-toggle {
  color: #fff;
  background-color: #d9534f;
  border-color: #d9534f;
}
.btn.link {
  font-weight: normal;
  color: var(--link-color);
  border-radius: 0;
  padding: 0;
  text-transform: inherit;
  font-size: inherit;
  vertical-align: baseline;
  line-height: inherit;
}
.btn.link,
.btn.link:active,
.btn.link.active,
.btn.link:disabled {
  background-color: transparent;
  box-shadow: none;
}
.btn.link,
.btn.link:focus,
.btn.link:active {
  border-color: transparent;
}
.btn.link:hover {
  border-color: transparent;
}
.btn.link:hover,
.btn.link:focus {
  color: var(--link-hover-color);
  text-decoration: underline;
  background-color: transparent;
}
.btn.link:disabled {
  color: #636c72;
}
.btn.link:disabled:hover,
.btn.link:disabled:focus {
  text-decoration: none;
}
.btn.large {
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  border-radius: 0.3rem;
}
.btn.small {
  padding: 0.25rem 0.5rem;
  font-size: 0.75rem;
  border-radius: 0.2rem;
}
.btn.xs {
  padding: 0.15rem 0.3rem;
  font-size: 0.75rem;
  border-radius: 0.2rem;
}
.btn.block {
  display: block;
  width: 100%;
}
.btn.block + .btn.block {
  margin-top: 5px;
}
.caret::after {
  display: inline-block;
  content: '';
  width: 0;
  height: 0;
  margin-left: 4px;
  vertical-align: middle;
  border-top: 4px solid;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}
.caret.up::after {
  border-top: 0;
  border-bottom: 4px solid;
}
.close {
  float: right;
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1;
  color: var(--black);
  text-shadow: 0 1px 0 var(--white);
  opacity: 0.2;
}
.close:hover {
  color: var(--black);
  text-decoration: none;
  cursor: pointer;
  opacity: 0.5;
}
button.close {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  outline: none;
}
.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 0 1 auto;
}
.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 2;
}
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 2;
}
.btn-group .btn + .btn,
.btn-group .btn + .btn-group,
.btn-group .btn-group + .btn,
.btn-group .btn-group + .btn-group,
.btn-group-vertical .btn + .btn,
.btn-group-vertical .btn + .btn-group,
.btn-group-vertical .btn-group + .btn,
.btn-group-vertical .btn-group + .btn-group {
  margin-left: -1px;
}
.btn-toolbar {
  display: flex;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}
.btn-group > .btn:not(:first-child):not(:last-child):not(.dropdown-toggle) {
  border-radius: 0;
}
.btn-group > .btn:first-child {
  margin-left: 0;
}
.btn-group > .btn:first-child:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:last-child:not(:first-child),
.btn-group > .dropdown-toggle:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group > .btn-group {
  float: left;
}
.btn-group > .btn-group:not(:first-child):not(:last-child) > .btn {
  border-radius: 0;
}
.btn-group > .btn-group:first-child:not(:last-child) > .btn:last-child,
.btn-group > .btn-group:first-child:not(:last-child) > .dropdown-toggle {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn-group:last-child:not(:first-child) > .btn:first-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group .dropdown-toggle:active,
.btn-group.open .dropdown-toggle {
  outline: 0;
}
.btn-group.small > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}
.btn-group.large > .btn {
  padding: 0.75rem 1.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}
.btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}
.btn + .dropdown-toggle-split::after {
  margin-left: 0;
}
.btn.small + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}
.btn.large + .dropdown-toggle-split {
  padding-right: 1.125rem;
  padding-left: 1.125rem;
}
.btn-group.open .dropdown-toggle {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.btn-group.open .dropdown-toggle.btn.link {
  box-shadow: none;
}
.btn-group-vertical {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical .btn,
.btn-group-vertical .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn + .btn,
.btn-group-vertical > .btn + .btn-group,
.btn-group-vertical > .btn-group + .btn,
.btn-group-vertical > .btn-group + .btn-group {
  margin-top: -1px;
  margin-left: 0;
}
.btn-group-vertical > .btn:not(:first-child):not(:last-child) {
  border-radius: 0;
}
.btn-group-vertical > .btn:first-child:not(:last-child) {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group-vertical > .btn:last-child:not(:first-child) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.btn-group-vertical > .btn-group:not(:first-child):not(:last-child) > .btn {
  border-radius: 0;
}
.btn-group-vertical > .btn-group:first-child:not(:last-child) > .btn:last-child,
.btn-group-vertical > .btn-group:first-child:not(:last-child) > .dropdown-toggle {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group-vertical > .btn-group:last-child:not(:first-child) > .btn:first-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
[data-toggle='buttons'] > .btn input[type='radio'],
[data-toggle='buttons'] > .btn input[type='checkbox'],
[data-toggle='buttons'] > .btn-group > .btn input[type='radio'],
[data-toggle='buttons'] > .btn-group > .btn input[type='checkbox'] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.modal-container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  -webkit-overflow-scrolling: touch;
}
.modal-background {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 950;
  background: var(--modal-background);
}
.modal-container > .modal-dialog {
  position: relative;
  width: auto;
  margin: 20px 10px;
  background-color: var(--page-background);
  background-clip: padding-box;
  border-radius: 0.5rem;
  box-shadow: var(--modal-shadow);
  outline: 0;
  padding: 1.5rem;
}
.modal-dialog .modal-content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.modal-dialog header {
  padding: 0 1rem 1rem;
  border-bottom: 2px solid var(--dabble-yellow);
}
.danger .modal-dialog header {
  border-bottom: 2px solid #d9534f;
}
.modal-dialog header::before,
.modal-dialog footer::before {
  display: table;
  content: ' ';
}
.modal-dialog header::after,
.modal-dialog footer::after {
  display: table;
  content: ' ';
  clear: both;
}
.modal-dialog header h2,
.modal-dialog header h3,
.modal-dialog header h4 {
  color: var(--text-color-normal);
  margin: 0;
}
.modal-dialog header h2 {
  font-size: 1.75rem;
}
.modal-dialog header h3 {
  font-size: 1.25rem;
}
.modal-dialog header h4 {
  font-size: 1rem;
}
.modal-dialog header .close {
  margin-top: -12px;
  margin-right: -10px;
}
.modal-dialog section {
  position: relative;
  padding: 0 1rem;
}
.modal-dialog section > :first-child {
  margin-top: 0;
}
.modal-dialog section > :last-child {
  margin-bottom: 0;
}
.modal-dialog footer {
  padding: 0 1rem;
  text-align: right;
}
.modal-dialog footer > :not(:last-child) {
  margin-right: 0.25rem;
}
.modal-dialog footer > :not(:first-child) {
  margin-left: 0.25rem;
}
.modal-dialog footer > .left {
  float: left;
}
@media (min-width: 544px) {
  .modal-container > .modal-dialog {
    max-width: 600px;
    margin: 80px auto;
    box-shadow: var(--modal-shadow-large);
  }
  .modal-container.small > .modal-dialog {
    max-width: 400px;
  }
}
@media (min-width: 992px) {
  .modal-container.large > .modal-dialog {
    max-width: 900px;
  }
}
@keyframes fade-wait {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  transition: all 0.2s ease-in-out;
}
.alert h1,
.alert h2,
.alert h3,
.alert h4,
.alert h5,
.alert h6 {
  color: inherit;
}
.alert a[href],
.alert .btn.link {
  font-weight: bold;
  color: inherit;
}
.alert a[href].action,
.alert .btn.link.action {
  font-style: italic;
  text-decoration: underline;
}
.alert.dismissible {
  padding-right: 2rem;
}
.alert .close {
  position: absolute;
  right: 1rem;
  color: inherit;
  line-height: 20px;
}
.global.alert {
  position: fixed;
  z-index: 1060;
  top: 50px;
  left: 10px;
  right: 10px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
}
@media (min-width: 544px) {
  .global.alert {
    width: 440px;
    left: 50%;
    margin-left: -220px;
    right: auto;
  }
}
.alert.success {
  background-color: #dff0d8;
  border-color: #c1e2b3;
  color: #3c763d;
}
.alert.success hr {
  border-top-color: #b2dba1;
}
.alert.success .alert-link {
  color: #2b542c;
}
.alert.info {
  background-color: #d9edf7;
  border-color: #bcdff1;
  color: #31708f;
}
.alert.info hr {
  border-top-color: #a6d5ec;
}
.alert.info .alert-link {
  color: #245269;
}
.alert.warning {
  background-color: #fcf8e3;
  border-color: #f7ecb5;
  color: #8a6d3b;
}
.alert.warning hr {
  border-top-color: #f5e79e;
}
.alert.warning .alert-link {
  color: #66512c;
}
.alert.danger {
  background-color: #f2dede;
  border-color: #ebcccc;
  color: #a94442;
}
.alert.danger hr {
  border-top-color: #e4b9b9;
}
.alert.danger .alert-link {
  color: #843534;
}
.dropdown {
  position: relative;
}
.dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 4px;
  vertical-align: middle;
  content: '';
  border-top: 4px solid;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}
.dropdown-toggle::after:focus {
  outline: 0;
}
.dropdown-menu {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 990;
  min-width: 160px;
  padding: 0 4px;
  font-size: 1rem;
  color: var(--text-color-light);
  outline: none;
  text-align: left;
  background-color: var(--menu-background);
  background-clip: padding-box;
  border: var(--menu-border);
  border-radius: 5px;
  box-shadow: var(--menu-shadow);
  -webkit-user-select: none;
  user-select: none;
}
.dropdown-menu:not(.exposed)::before {
  content: '';
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  cursor: default;
}
.dropdown-submenu:not(.exposed)::before {
  content: inherit;
  position: inherit;
  top: inherit;
  right: inherit;
  bottom: inherit;
  left: inherit;
  cursor: default;
}
.dropdown-menu hr {
  margin: 4px 8px;
  border-top-color: var(--menu-separator-color);
}
.dropdown-menu .dropdown-item {
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 16px;
  line-height: 20px;
  color: var(--text-color-normal);
  padding: 8px 20px;
  margin: 4px 0;
  border-radius: 4px;
  clear: both;
  font-weight: normal;
  text-align: inherit;
  background: none;
  border: 0;
  position: relative;
  white-space: nowrap;
  text-decoration: none;
  outline: none;
  cursor: pointer;
}
.is-mouse .dropdown-menu .dropdown-item {
  font-size: 14px;
  line-height: 18px;
  padding: 6px 16px;
}
.dropdown-menu .dropdown-item:hover,
.dropdown-menu .dropdown-item.open {
  background-color: var(--menu-hover-color);
}

.dropdown-menu[data-popper-placement] .dropdown-arrow {
  position: absolute;
  width: 0;
  height: 0;
}
.dropdown-menu .dropdown-arrow::before,
.dropdown-menu .dropdown-arrow::after {
  content: '';
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}
/* .dropdown-menu[data-popper-placement^="right"].with-arrow,
.dropdown-menu[data-popper-placement^="left"].with-arrow {
  margin: -1px 2px;
}
.dropdown-menu[data-popper-placement^="top"].with-arrow,
.dropdown-menu[data-popper-placement^="bottom"].with-arrow {
  margin: 2px -1px;
} */
.dropdown-menu[data-popper-placement^='right'] .dropdown-arrow::before,
.dropdown-menu[data-popper-placement^='left'] .dropdown-arrow::before,
.dropdown-menu[data-popper-placement^='right'] .dropdown-arrow::after,
.dropdown-menu[data-popper-placement^='left'] .dropdown-arrow::after {
  top: -8px;
}
.dropdown-menu[data-popper-placement^='right'] .dropdown-arrow {
  left: 0;
}
.dropdown-menu[data-popper-placement^='left'] .dropdown-arrow {
  right: 0;
}
.dropdown-menu[data-popper-placement^='right'] .dropdown-arrow::before {
  border-width: 8px 7px 8px 0;
  border-right-color: var(--menu-border-color);
  left: -7px;
}
.dropdown-menu[data-popper-placement^='right'] .dropdown-arrow::after {
  border-width: 8px 7px 8px 0;
  border-right-color: var(--menu-background);
  left: -6px;
}
.dropdown-menu[data-popper-placement^='left'] .dropdown-arrow::before {
  border-width: 8px 0 8px 7px;
  border-left-color: var(--menu-border-color);
  right: -7px;
}
.dropdown-menu[data-popper-placement^='left'] .dropdown-arrow::after {
  border-width: 8px 0 8px 7px;
  border-left-color: var(--menu-background);
  right: -6px;
}
.dropdown-menu[data-popper-placement^='top'] .dropdown-arrow::before,
.dropdown-menu[data-popper-placement^='bottom'] .dropdown-arrow::before,
.dropdown-menu[data-popper-placement^='top'] .dropdown-arrow::after,
.dropdown-menu[data-popper-placement^='bottom'] .dropdown-arrow::after {
  left: -8px;
}
.dropdown-menu[data-popper-placement^='top'] .dropdown-arrow {
  bottom: 0;
}
.dropdown-menu[data-popper-placement^='bottom'] .dropdown-arrow {
  top: 0;
}
.dropdown-menu[data-popper-placement^='top'] .dropdown-arrow::before {
  border-width: 7px 8px 0 8px;
  border-top-color: var(--menu-border-color);
  bottom: -7px;
}
.dropdown-menu[data-popper-placement^='top'] .dropdown-arrow::after {
  border-width: 7px 8px 0 8px;
  border-top-color: var(--menu-background);
  bottom: -6px;
}
.dropdown-menu[data-popper-placement^='bottom'] .dropdown-arrow::before {
  border-width: 0 8px 7px 8px;
  border-bottom-color: var(--menu-border-color);
  top: -7px;
}
.dropdown-menu[data-popper-placement^='bottom'] .dropdown-arrow::after {
  border-width: 0 8px 7px 8px;
  border-bottom-color: var(--menu-background);
  top: -6px;
}
.dropdown-menu .dropdown-item {
  position: relative;
}
.dropdown-menu .dropdown-item:active {
  outline: none;
}

.dropdown-menu .icon {
  line-height: inherit;
}
.dropdown-menu .dropdown-item .icon {
  font-size: 20px;
  margin-right: 6px;
}
.dropdown-menu .dropdown-item .icon.prefix {
  position: absolute;
  line-height: 1;
  font-size: 10px;
  margin-left: -10px;
}
/* .dropdown-menu .dropdown-item:active {
  text-decoration: none;
  background: none;
  outline: 0;
  box-shadow: none;
} */
.dropdown-menu .dropdown-item[disabled],
.dropdown-menu .dropdown-item[disabled]:hover,
.dropdown-menu .dropdown-item[disabled]:active,
.dropdown-menu .dropdown-item.disabled,
.dropdown-menu .dropdown-item.disabled:hover,
.dropdown-menu .dropdown-item.disabled:active {
  opacity: 0.5;
  text-decoration: none;
  cursor: default;
  background: none;
  pointer-events: none;
}
.dropdown-menu .dropdown-item.inactive,
.dropdown-menu .dropdown-item.inactive:hover,
.dropdown-menu .dropdown-item.inactive:active {
  text-decoration: none;
  cursor: default;
  background: none;
  padding: 2px 16px;
}
.dropdown-menu header {
  display: block;
  padding: 5px 20px;
  font-size: 0.875rem;
  color: #818a91;
  white-space: nowrap;
  cursor: default;
}
.dropdown.right .dropdown-menu {
  right: 0;
  left: auto;
}
.dropdown.left .dropdown-menu {
  right: auto;
  left: 0;
}
.dropdown.up .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin: 0 0 2px;
}
.dropdown-submenu {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 990;
  min-width: 160px;
  padding: 0 4px;
  font-size: 1rem;
  color: var(--text-color-light);
  outline: none;
  text-align: left;
  background-color: var(--menu-background);
  background-clip: padding-box;
  border: var(--menu-border);
  border-radius: 5px;
  box-shadow: var(--menu-shadow);
  -webkit-user-select: none;
  user-select: none;
}
.dropdown-submenu hr {
  margin: 4px 8px;
  border-top-color: var(--menu-separator-color);
}

.dropdown-toggle.up::after {
  border-top: 0;
  border-bottom: 0.3em solid;
}
.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  font-family: var(--font-family-ui);
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-break: auto;
  line-height: normal;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  white-space: normal;
  word-break: normal;
  word-spacing: normal;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
  pointer-events: none;
}
.tooltip.in {
  opacity: 1;
  transition: opacity 0.15s linear 0.2s;
}
.tooltip[data-popper-placement^='top'] {
  padding-bottom: 5px;
  margin-bottom: 3px;
}
.tooltip-arrow {
  position: absolute;
  border-color: transparent;
  border-style: solid;
}
.tooltip[data-popper-placement^='top'] .tooltip-arrow {
  bottom: 0;
  left: 50%;
  margin-left: -3px;
  border-width: 6px 6px 0;
  border-top-color: var(--tooltip-background);
}
.tooltip[data-popper-placement^='right'] {
  padding-left: 5px;
  margin-left: 3px;
}
.tooltip[data-popper-placement^='right'] .tooltip-arrow {
  left: 0;
  top: 50%;
  margin-top: -3px;
  border-width: 6px 6px 6px 0;
  border-right-color: var(--tooltip-background);
}
.tooltip[data-popper-placement^='bottom'] {
  padding-top: 5px;
  margin-top: 3px;
}
.tooltip[data-popper-placement^='bottom'] .tooltip-arrow {
  top: 0;
  left: 50%;
  margin-left: -3px;
  border-width: 0 6px 6px;
  border-bottom-color: var(--tooltip-background);
}
.tooltip[data-popper-placement^='left'] {
  padding-right: 5px;
  margin-right: 3px;
}
.tooltip[data-popper-placement^='left'] .tooltip-arrow {
  right: 0;
  top: 50%;
  margin-top: -3px;
  border-width: 6px 0 6px 6px;
  border-left-color: var(--tooltip-background);
}
.tooltip-inner {
  max-width: 200px;
  padding: 6px 12px;
  color: var(--tooltip-text-color);
  text-align: center;
  background-color: var(--tooltip-background);
  border-radius: 0.25rem;
}
.tooltip-inner .highlight {
  font-weight: bold;
  color: var(--tooltip-highlight);
  text-shadow:
    0 0 2px rgba(0, 0, 0, 0.6),
    0 1px 2px rgba(0, 0, 0, 0.5);
}
.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  position: relative;
  top: -0.125em;
  font-size: 75%;
  font-weight: bold;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}
.badge:empty {
  display: none;
}
.btn .badge {
  position: relative;
  top: -1px;
}
a.badge .hover,
a.badge .focus {
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}
.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}
.badge.default {
  background-color: #636c72;
}
.badge.default[href] .hover,
.badge.default[href] .focus {
  background-color: #4b5257;
}
.badge.primary {
  background-color: rgb(var(--dabble-blue-500));
}
.badge.primary[href] .hover,
.badge.primary[href] .focus {
  background-color: rgb(var(--dabble-blue-600));
}
.badge.secondary {
  background-color: #5bc0de;
}
.badge.success {
  background-color: #5cb85c;
}
.badge.success[href] .hover,
.badge.success[href] .focus {
  background-color: #449d44;
}
.badge.info {
  background-color: #5bc0de;
}
.badge.info[href] .hover,
.badge.info[href] .focus {
  background-color: #31b0d5;
}
.badge.warning {
  background-color: #f0ad4e;
}
.badge.warning[href] .hover,
.badge.warning[href] .focus {
  background-color: #ec971f;
}
.badge.danger {
  background-color: #d9534f;
}
.badge.danger[href] .hover,
.badge.danger[href] .focus {
  background-color: #c9302c;
}
.nav {
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
}
.nav::before {
  display: table;
  content: ' ';
}
.nav::after {
  display: table;
  content: ' ';
  clear: both;
}
.nav > li {
  position: relative;
  display: block;
}
.nav > li > a,
.nav > li > .btn.link {
  position: relative;
  display: block;
  padding: 10px 15px;
}
.nav > li > a:hover,
.nav > li > a:focus,
.nav > li > .btn.link:hover,
.nav > li > .btn.link:focus {
  text-decoration: none;
  background-color: var(--tab-hover-background);
}
.nav > li.disabled > a,
.nav > li.disabled > .btn.link {
  color: #636c72;
}
.nav > li.disabled > a:hover,
.nav > li.disabled > a:focus,
.nav > li.disabled > .btn.link:hover,
.nav > li.disabled > .btn.link:focus {
  color: #636c72;
  text-decoration: none;
  background-color: transparent;
  cursor: default;
}
.nav .open > a,
.nav .open > a:hover,
.nav .open > a:focus,
.nav .open > .btn.link,
.nav .open > .btn.link:hover,
.nav .open > .btn.link:focus {
  background-color: var(--tab-hover-background);
  border-color: rgb(var(--dabble-blue-500));
}
.tabs {
  border-bottom: 1px solid var(--tab-border-color);
}
.tabs > li {
  float: left;
  margin-bottom: -1px;
}
.tabs > li > a,
.tabs > li > .btn.link {
  margin-right: 2px;
  line-height: normal;
  border: 1px solid transparent;
  border-radius: 0.25rem 0.25rem 0 0;
}
.tabs > li > a:hover,
.tabs > li > .btn.link:hover {
  border-color: var(--tab-hover-background) var(--tab-hover-background) var(--tab-border-color);
}
.tabs > li.active > a,
.tabs > li.active > a:hover,
.tabs > li.active > a:focus,
.tabs > li.active > .btn.link,
.tabs > li.active > .btn.link:hover,
.tabs > li.active > .btn.link:focus {
  color: var(--text-color-light);
  background-color: var(--page-background);
  border: 1px solid var(--tab-border-color);
  border-bottom-color: transparent;
  cursor: default;
}
