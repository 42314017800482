/* latin */
@font-face {
  font-family: 'Crimson Text';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local('Crimson Text Italic'), local('CrimsonText-Italic'),
    url(./CrimsonText/wlpogwHKFkZgtmSR3NB0oRJfajhRK_Z_3rhH.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: 'Crimson Text';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: local('Crimson Text Bold Italic'), local('CrimsonText-BoldItalic'),
    url(./CrimsonText/wlprgwHKFkZgtmSR3NB0oRJfajDqDuNS_LVtfaKv.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: 'Crimson Text';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Crimson Text Regular'), local('CrimsonText-Regular'),
    url(./CrimsonText/wlp2gwHKFkZgtmSR3NB0oRJfbwhTIfFd3A.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: 'Crimson Text';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Crimson Text Bold'), local('CrimsonText-Bold'),
    url(./CrimsonText/wlppgwHKFkZgtmSR3NB0oRJX1C1GDNNQ9rJPfw.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
