@charset "utf-8";

button.icon {
  position: relative;
  display: inline-flex;
  justify-content: center;
  appearance: none;
  --webkit-appearance: none;
  background: transparent;
  border-radius: 999px;
  margin: 0;
  padding: 0.2em;
  font-size: 20px;
  outline: none;
  border: 0;
  color: var(--text-color-lighter);
  text-shadow: var(--text-highlight);
  transition: 0.15s color linear;
  cursor: pointer;
  transition: background-color 0.15s linear, opacity 0.15s linear;
}
button.icon.feature-disabled::before {
  content: '';
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: var(--dabble-red);
  position: absolute;
  top: 6px;
  left: 5px;
}
button.icon.large {
  font-size: 24px;
}
button.icon.small {
  font-size: 16px;
}
.size-desktop button.icon:not([disabled]):hover,
.size-desktop button.icon:not([disabled]):focus-visible {
  color: var(--text-color-normal);
}
button.icon.light {
  color: var(--text-color-lighterer);
}
.size-desktop button.icon.light:not([disabled]):hover,
.size-desktop button.icon.light:not([disabled]):focus-visible {
  color: var(--text-color-lighter);
}
button.icon[disabled] {
  opacity: 0.5;
  cursor: default;
}
/*.size-desktop button.icon:not([disabled]):hover::before, .size-desktop button.icon:not([disabled]):focus::before {
  position: relative;
}*/
.size-desktop button.icon:not([disabled]):hover,
.size-desktop button.icon:not([disabled]):focus-visible {
  background-color: rgba(0, 0, 0, 0.08);
}
button.with-icon {
  font-family: 'dabble-icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  display: inline-flex;
  justify-content: center;
  align-items: center;
  appearance: none;
  --webkit-appearance: none;
  background: none;
  outline: none;
  border: 0;
  color: var(--text-color-lighter);
  text-shadow: var(--text-highlight);
  cursor: pointer;
}
button.with-icon::before {
  font-size: 20px;
}
.size-desktop button.with-icon:not([disabled]):hover {
  color: var(--text-color-normal);
}
button.with-icon[disabled] {
  opacity: 0.5;
  cursor: default;
}
