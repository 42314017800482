@media print {
  @page {
    size: auto;
    margin: 1in;
  }
  @page :footer {
    display: none;
  }
  @page :header {
    display: none;
  }
  /* fixes print only a single page */
  html {
    height: auto;
  }
  body {
    position: relative;
    overflow: visible;
  }
  /* required to view all pages */
  .dabble-app {
    overflow: visible !important;
  }
  /* removes unneeded ui */
  .app-header,
  .status-bar,
  #app-header,
  .side-nav,
  .right-pane,
  .left-nav,
  .desktop-nav {
    display: none !important;
  }
  /* required to view all pages */
  .project-screen,
  .project-container {
    overflow: visible !important;
    contain: none !important;
  }
  /* required for displaying all pages*/
  .workspace {
    overflow: visible !important;
    contain: none !important;
  }
  /* removes page styling */
  body {
    background: none;
  }
  #workspace #editor {
    position: absolute !important;
    left: 0 !important;
    top: 0 !important;
    overflow: visible !important;
    padding: 0 !important;
  }
  /* needed to break pages */
  .page,
  .page-wrapper,
  .project-screen,
  .no-frame,
  .center-space,
  .workspace,
  .dabble-app,
  .editable-content {
    display: inherit !important;
    contain: inherit !important;
    margin: 0 !important;
    padding: 0 !important;
  }
  .dabble-app {
    position: relative !important;
  }

  .workspace > div {
    display: none !important;
  }
  .workspace > div.center-space {
    display: block !important;
  }

  .title-page {
    height: auto !important;
  }

  img {
    break-inside: avoid !important;
    page-break-inside: avoid !important;
  }

  .editable-content .image img {
    max-width: 100%;
    max-height: calc(var(--page-height) - 2 * var(--page-padding)) !important;
  }

  .image[data-style='full-page'] {
    page-break-before: always !important;
    page-break-after: always !important;
    break-before: always !important;
    break-after: always !important;
  }

  .comments,
  .stickies {
    display: none !important;
  }
  .editable-content format-comment {
    background: transparent !important;
  }
  .contents {
    padding-bottom: 0px !important;
  }
  .row {
    height: auto !important;
  }
  .full-page-image-container {
    display: block !important;
    page-break-inside: avoid !important;
    page-break-after: always !important;
    max-height: calc(var(--page-height) - 2 * var(--page-padding)) !important;
    overflow: hidden !important;
  }
  .folder-view .folder-title h1 {
    background: transparent !important;
    box-shadow: none !important;
    border-top: 1px #eee solid;
    border-bottom: 1px #eee solid;
  }
  .page {
    box-shadow: none !important;
    max-width: none !important;
    min-height: none !important;
    margin: 0 !important;
    padding: 0 !important;

    page-break-after: always !important;
    break-after: always !important;

    min-height: unset !important;
  }
  .page-wrapper:last-child .page {
    page-break-after: auto !important;
    break-after: auto !important;
  }
  .fill-page .typewriter-editor {
    padding-bottom: 0 !important;
  }
  .editable-content .image[data-style='full-page'] {
    margin: 0 !important;
    page-break-before: always !important;
    page-break-after: always !important;
    page-break-inside: avoid !important;
  }
  .editable-content .image[data-style='full-page']::before,
  .editable-content .image[data-style='full-page']::after {
    display: none !important;
  }
  .plot-point {
    page-break-inside: avoid !important;
    break-inside: always !important;
  }
  .notecard {
    box-shadow: none !important;
    border: 1px solid #eee !important;
  }
  .notecard .text {
    background: transparent !important;
  }
  .notecard .title {
    border-bottom: 0px !important;
  }
  .add-point-button {
    display: none !important;
  }
}
