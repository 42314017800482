.typewriter-editor format-highlight {
  display: inline;
  border-radius: 0.6em 0.4em 0.5em 0.125em;
  padding: 0 0.3em 0 0.3em;
  margin: 0 -0.3em 0 -0.3em;
  box-decoration-break: clone;
  -webkit-box-decoration-break: clone;
}
.typewriter-editor format-highlight {
  background-image: linear-gradient(
    119deg,
    hsla(var(--hsl), 0),
    hsla(var(--hsl), 0) 0.2em,
    hsl(var(--hsl)) 1.25em,
    hsl(var(--hsl)) calc(100% - 1em),
    hsla(var(--hsl), 0) calc(100% - 0.1em),
    hsla(var(--hsl), 0)
  );
}
.typewriter-editor format-highlight.yellow {
  --hsl: 55, 100%, 80%;
}
.typewriter-editor format-highlight.purple {
  --hsl: 290, 100%, 88%;
}
.typewriter-editor format-highlight.teal {
  --hsl: 191, 93%, 83%;
}
.typewriter-editor format-highlight.green {
  --hsl: 89, 52%, 83%;
}
.typewriter-editor format-highlight.blue {
  --hsl: 216, 71%, 80%;
}
.typewriter-editor format-highlight.black {
  background: currentColor;
  border-radius: 1px;
  padding: 0 0.1em 0 0.1em;
  margin: 0 -0.1em 0 -0.1em;
}

.swatch.yellow {
  background-color: hsl(53, 38%, 27%);
}
.swatch.purple {
  background-color: hsl(290, 100%, 88%);
}
.swatch.teal {
  background-color: hsl(191, 93%, 83%);
}
.swatch.green {
  background-color: hsl(89, 52%, 83%);
}
.swatch.blue {
  background-color: hsl(216, 71%, 80%);
}

@media screen {
  html[data-theme='dark'] .typewriter-editor format-highlight.yellow {
    --hsl: 55, 100%, 24%;
  }
  html[data-theme='dark'] .typewriter-editor format-highlight.purple {
    --hsl: 290, 60%, 28%;
  }
  html[data-theme='dark'] .typewriter-editor format-highlight.teal {
    --hsl: 191, 93%, 28%;
  }
  html[data-theme='dark'] .typewriter-editor format-highlight.green {
    --hsl: 89, 52%, 28%;
  }
  html[data-theme='dark'] .typewriter-editor format-highlight.blue {
    --hsl: 216, 71%, 34%;
  }
}
