@import './props-colors.css';

:root {
  color-scheme: light dark; /* Support dark mode on MacOS/iOS */

  --dabble-off-white: #e9f1df;
  --dabble-red: #f2385a;
  --dabble-yellow: #f5a503;
  --dabble-teal: #4ad9d9;
  --dabble-blue: rgb(var(--dabble-blue-500));
  --dabble-blue-rgb: 54, 177, 191;
  --bright-blue: #3772ff;
  --dabble-light-gray: #f3f3f3;
  --dabble-gray: #ccc;
  --dabble-light-teal: #edfbff;

  --white: #fff;
  --black: #000;
  --red: #d9534f;
  --orange: #f0ad4e;
  --yellow: #ffd500;
  --green: #5cb85c;
  --green-dark: #118a11;
  --blue: #36b1bf;
  --teal: #5bc0de;
  --pink: #ff5b77;
  --purple: #613d7c;

  --gray-dark: #292b2c;
  --gray: #464a4c;
  --gray-light: #636c72;
  --grey-light-light: #909da7;
  --gray-lightish: #cdcfd1;
  --gray-lighter-2: #ecebeb;
  --gray-lighter: #eceeef;
  --gray-lightest: #f7f7f9;

  --brand-primary: var(--blue);
  --brand-success: var(--green);
  --brand-info: var(--teal);
  --brand-warning: var(--orange);
  --brand-danger: var(--red);
  --brand-inverse: var(--gray-dark);
  --table-borders: #dee2e6;

  --link-color: var(--brand-primary);
  --link-decoration: none;
  --link-hover-color: #257a83;
  --link-hover-decoration: underline;

  --border-radius: 0.25rem;
  --border-radius-lg: 0.3rem;
  --border-radius-sm: 0.2rem;

  --form-border: 1px solid rgba(0, 0, 0, 0.15);
  --font-size-base: 1rem; /* Assumes the browser default, typically `16px` */
  --font-size-lg: 1.25rem;
  --font-size-xl: 1.5rem;
  --font-size-xxl: 2rem;
  --font-size-xxxl: 3rem;
  --font-size-sm: 0.875rem;
  --font-size-xs: 0.75rem;
  --font-size-xxs: 0.55rem;
  --font-size: var(--font-size-base);

  /* --font-family-ui: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'; */
  --font-family-ui: 'Outfit', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
    'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  --font-family-ui-serif: Georgia, 'Times New Roman', Times, serif;
  --font-family-text: 'PT Serif', sans-serif;
  --font-family-header: 'PT Serif', serif;

  --caret-width: 0.3em;

  /* --background: url(../images/textured-background-light.jpg) top left / 1024px 682px repeat fixed #f0efee; */
  --background: #cfcdc9;
  --background-darkened: #c5c4be;
  --background-lightened: #d9d8d4;

  --bubble-menu-background-gradient: linear-gradient(to bottom, rgba(49, 49, 47, 0.99), #262625);

  --modal-background: rgba(0, 0, 0, 0.5);
  --modal-shadow: 0 2px 8px rgba(0, 0, 0, 0.5);
  --modal-shadow-large: 0 5px 15px rgba(0, 0, 0, 0.5);

  --flat-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2), 0 1px 3px rgba(0, 0, 0, 0.15);

  --red-line: #fad1e1;
  --blue-line: #d1f0fa;

  --tooltip-background: #333;
  --tooltip-text-color: #fff;
  --tooltip-highlight: var(--dabble-yellow);

  --horizontal-bevel-top: 0 -1px #999, inset 0 1px #eee;
  --horizontal-bevel: 0 1px #eee, inset 0 -1px #999;
  --vertical-bevel: -1px 0 #999, inset 1px 0 #eee;
  --home-background: #fff;
  --home-project-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  --home-template-shadow: 0 0 2px rgba(0, 0, 0, 0.2), 0 1px 4px rgba(0, 0, 0, 0.2);
  --home-project-hover-shadow: 0 3px 12px 3px rgba(0, 0, 0, 0.2);
  --home-project-cover-shadow: 0 2px 6px -1px rgba(0, 0, 0, 0.3), 0 1px 2px 1px rgba(0, 0, 0, 0.2);

  --link-color: #007986;
  --link-hover-color: #006570;

  --page-width: 816px;
  --page-height: 1056px;
  --page-padding: 24px;
  --page-background: #fff;
  --page-shadow: 0 2px 6px rgba(0, 0, 0, 0.2), 0 1px 4px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.1);
  --folder-background: var(--gray-lightest);
  --folder-shadow: 0 1px 8px rgba(0, 0, 0, 0.2), 0 0 0 1px rgba(0, 0, 0, 0.05), inset 0 1px var(--white);

  --global-edit-button-shadow: 0 2px 4px rgba(0, 0, 0, 0.16), 0 7px 12px 4px rgba(0, 0, 0, 0.18);

  --text-color-normal: #222;
  --text-color-light: #555;
  --text-color-lighter: #666;
  --text-color-lighterer: #777;
  --text-color-lightest: #aaa;
  --text-lowlight: 0 1px 1px rgba(0, 0, 0, 0.2);
  --text-color-faint: #eee;
  --text-highlight: 0 1px 1px rgba(255, 255, 255, 0.8);
  --text-highlight-inverse: 0 -1px 1px rgba(255, 255, 255, 0.8);
  --selection-color: hsla(215, 100%, 77%, 0.6);
  --selection-color-light: hsla(215, 100%, 77%, 0.2);
  --selection-color-inactive: rgba(169, 169, 169, 0.6);

  --loading-background: #f6f7f8;
  --loading-shimmer-light: #eee;
  --loading-shimmer-dark: #ddd;

  --app-header-height: 40px;
  --app-header-background: #eee; /* linear-gradient(to bottom, #e8e6e8, #d2d0d2); */
  --app-bar: #ccc;
  --app-header-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.15), 0 1px 6px rgba(0, 0, 0, 0.2);
  --app-header-button-hover: rgba(0, 0, 0, 0.1);

  --status-bar-height: 36px;
  --status-bar-background: #eee;
  --status-bar-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 0 -1px 2px rgba(0, 0, 0, 0.2);

  --side-nav-width: 226px;
  --side-nav-background: var(--dabble-light-gray);
  --right-nav-shadow: 2px 0 6px rgba(0, 0, 0, 0.2);
  --right-nav-max-width: 520px;
  --side-tools-border: 1px solid #ccc;
  --side-nav-border: 1px solid #aaa;
  --side-nav-color-light: #e0e0e0;
  --side-nav-color-mid: #d6d6d6;
  --side-nav-color-dark: #ccc;
  --side-nav-selected-background: var(--dabble-blue);
  --side-nav-selected-text-color: #fff;
  --side-nav-selected-text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
  --side-nav-icon-color: #777;
  --side-nav-arrow-hover-color: #222;
  --side-nav-arrow-selected-color: rgba(255, 255, 255, 0.7);
  --side-nav-arrow-selected-hover-color: #fff;
  --top-down-shadow: 0 2px 1px rgba(0, 0, 0, 0.1), 0 2px 2px rgba(0, 0, 0, 0.05), 0 3px 3px rgba(0, 0, 0, 0.1);

  --side-nav-item-height: 24px;
  --side-nav-item-inset: 12px;
  --side-nav-item-padding: 20px;

  --menu-background: #fff;
  --menu-border-color: rgba(0, 0, 0, 0.15);
  --menu-border: 1px solid var(--menu-border-color);
  --menu-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  --menu-hover-color: rgba(0, 0, 0, 0.08);
  --menu-separator-color: #e5e5e5;
  --menu-arrow-color: rgba(0, 0, 0, 0.2);

  --actions-shadow: -1px -1px 0 rgba(255, 255, 255, 0.6), 1px 1px 3px rgba(0, 0, 0, 0.3);
  --plot-hover-highlight: rgba(255, 255, 255, 0.1);
  --plot-first-line-highlight: #c0bfbc;
  --plot-chapter-border: 1px dashed #aaa;
  --card-shadow-color: rgba(0, 0, 0, 0.2);
  --tab-border-color: #ddd;
  --tab-hover-background: #eee;

  --calendar-background: #f1f1f1;
  --calendar-border: 1px solid #e3e3e3;

  --card-width: 480px; /* 5 inches */
  --card-height: 288px; /* 3 inches */

  --paragraph-indent: 0;
  --paragraph-margin: 1.2em;
  --paragraph-line-spacing: 1.8;

  --chapter-title-prefix-line-spacing: 0.5em;
}

.is-touch {
  --app-header-height: 56px;
  --status-bar-height: 48px;

  --side-nav-item-height: 48px;
  --side-nav-item-inset: 8px;
  --side-nav-item-padding: 32px;
}

.is-touch .prefer-desktop {
  --app-header-height: 40px;
  --status-bar-height: 36px;

  --side-nav-item-height: 24px;
  --side-nav-item-inset: 12px;
  --side-nav-item-padding: 20px;
}

.size-tablet {
  --page-padding: 48px;
}
.size-desktop {
  --page-padding: 96px;
}
