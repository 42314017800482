:root {
  --input-line-height: 1.25;
  --input-padding-x: 0.75rem;
  --input-padding-y: 0.5rem;
  --input-height: 2.25rem; /* line-height + input-padding-y * 2 */
  --input-focus-border: #94d9e1;
  --input-focus-shadow: rgba(148, 217, 225, 0.6);
}

input,
button,
select,
textarea {
  font: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
}
label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 0.5rem;
  font-size: var(--font-size-base);
  color: var(--text-color-light);
}
.form-group {
  margin-bottom: 1rem;
}
.form-text {
  display: block;
  margin-top: 0.25rem;
}
.text-muted {
  color: var(--gray-light) !important;
}
.form-control {
  display: block;
  width: 100%;
  padding: var(--input-padding-y) var(--input-padding-x);
  line-height: var(--input-line-height);
  font-size: var(--font-size-base);
  font-family: var(--font-family-ui);
  background-color: var(--white);
  color: var(--gray);
  background-image: none;
  background-clip: padding-box;
  border: var(--form-border);
  border-radius: var(--border-radius);
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:focus {
  color: var(--gray);
  background-color: var(--white);
  border-color: var(--input-focus-border);
  outline: none;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px var(--input-focus-shadow);
}
.form-control::placeholder {
  opacity: 0.5;
  color: var(--gray);
}
.form-control.disabled,
.form-control:disabled,
.form-control[readonly] {
  background-color: var(--gray-lighter);
  opacity: 1;
}
.form-control:disabled {
  cursor: not-allowed;
}
select.form-control:not([size]):not([multiple]) {
  height: 38px;
}
select.form-control:focus::-ms-value {
  color: var(--gray);
  background-color: var(--white);
}
.form-control[type='file'],
.form-control[type='range'] {
  display: block;
}
form .error input[type='text'],
form .error input[type='email'],
form .error input[type='password'],
form .error input[type='search'] {
  border-color: var(--red);
}
.error-message {
  font-size: 14px;
  color: var(--red);
}
.form-check {
  position: relative;
  display: block;
  margin-bottom: 0.5rem;
}
.form-check.disabled .form-check-label {
  color: var(--gray-light);
  cursor: default;
}
.form-check-label {
  padding-left: 1.25rem;
  margin-bottom: 0;
  cursor: pointer;
}
.form-check-input {
  position: absolute;
  margin-top: 0.25rem;
  margin-left: -1.25rem;
  margin-right: 0.25rem;
}
.form-check-input:only-child {
  position: static;
}
.input-group {
  position: relative;
  display: flex;
  width: 100%;
}
.input-group .form-control {
  position: relative;
  z-index: 2;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
}
.input-group .form-control:hover,
.input-group .form-control:focus,
.input-group .form-control:active {
  z-index: 3;
}
.input-group-addon,
.input-group-btn,
.input-group .form-control {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.input-group-addon:not(:first-child):not(:last-child),
.input-group-btn:not(:first-child):not(:last-child),
.input-group .form-control:not(:first-child):not(:last-child) {
  border-radius: 0;
}
.input-group-addon,
.input-group-btn {
  white-space: nowrap;
  vertical-align: middle;
}
.input-group-addon {
  padding: var(--input-padding-y) var(--input-padding-x);
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: normal;
  line-height: var(--input-line-height);
  color: var(--gray);
  text-align: center;
  background-color: var(--gray-lighter);
  border: var(--form-border);
  border-radius: var(--border-radius);
}
.input-group-addon.icon {
  font-size: 20px;
  line-height: 1;
}
.input-group-addon input[type='radio'],
.input-group-addon input[type='checkbox'] {
  margin-top: 0;
}
.input-group .form-control:not(:last-child),
.input-group-addon:not(:last-child),
.input-group-btn:not(:last-child) > .btn,
.input-group-btn:not(:last-child) > .btn-group > .btn,
.input-group-btn:not(:last-child) > .dropdown-toggle,
.input-group-btn:not(:first-child) > .btn:not(:last-child):not(.dropdown-toggle),
.input-group-btn:not(:first-child) > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group-addon:not(:last-child) {
  border-right: 0;
}
.input-group .form-control:not(:first-child),
.input-group-addon:not(:first-child),
.input-group-btn:not(:first-child) > .btn,
.input-group-btn:not(:first-child) > .btn-group > .btn,
.input-group-btn:not(:first-child) > .dropdown-toggle,
.input-group-btn:not(:last-child) > .btn:not(:first-child),
.input-group-btn:not(:last-child) > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.form-control + .input-group-addon:not(:first-child) {
  border-left: 0;
}
.input-group-btn {
  position: relative;
  font-size: 0;
  white-space: nowrap;
}
.input-group-btn > .btn {
  position: relative;
  flex: 1;
}
.input-group-btn > .btn + .btn {
  margin-left: -1px;
}
.input-group-btn > .btn:hover,
.input-group-btn > .btn:focus,
.input-group-btn > .btn:active {
  z-index: 3;
}
.input-group-btn:not(:last-child) > .btn,
.input-group-btn:not(:last-child) > .btn-group {
  margin-right: -1px;
}
.input-group-btn:not(:first-child) > .btn,
.input-group-btn:not(:first-child) > .btn-group {
  z-index: 2;
  margin-left: -1px;
}
.input-group-btn:not(:first-child) > .btn:hover,
.input-group-btn:not(:first-child) > .btn:focus,
.input-group-btn:not(:first-child) > .btn:active,
.input-group-btn:not(:first-child) > .btn-group:hover,
.input-group-btn:not(:first-child) > .btn-group:focus,
.input-group-btn:not(:first-child) > .btn-group:active {
  z-index: 3;
}
