/* latin */
@font-face {
  font-family: 'OpenDyslexic';
  font-style: normal;
  font-weight: normal;
  src: url(./OpenDyslexic/OpenDyslexic-Regular.woff2) format('woff2');
}
/* latin */
@font-face {
  font-family: 'OpenDyslexic';
  font-style: italic;
  font-weight: normal;
  src: url(./OpenDyslexic/OpenDyslexic-Italic.woff2) format('woff2');
}
/* latin */
@font-face {
  font-family: 'OpenDyslexic';
  font-style: normal;
  font-weight: bold;
  src: url(./OpenDyslexic/OpenDyslexic-Bold.woff2) format('woff2');
}
/* latin */
@font-face {
  font-family: 'OpenDyslexic';
  font-style: italic;
  font-weight: bold;
  src: url(./OpenDyslexic/OpenDyslexic-BoldItalic.woff2) format('woff2');
}
