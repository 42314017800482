/* cyrillic-ext */
@font-face {
  font-family: 'PT Serif';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local('PT Serif Italic'), local('PTSerif-Italic'),
    url(./PTSerif/EJRTQgYoZZY2vCFuvAFT_rC1cgT9rct48Q.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'PT Serif';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local('PT Serif Italic'), local('PTSerif-Italic'),
    url(./PTSerif/EJRTQgYoZZY2vCFuvAFT_rm1cgT9rct48Q.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin-ext */
@font-face {
  font-family: 'PT Serif';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local('PT Serif Italic'), local('PTSerif-Italic'),
    url(./PTSerif/EJRTQgYoZZY2vCFuvAFT_rO1cgT9rct48Q.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'PT Serif';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local('PT Serif Italic'), local('PTSerif-Italic'),
    url(./PTSerif/EJRTQgYoZZY2vCFuvAFT_r21cgT9rcs.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'PT Serif';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: local('PT Serif Bold Italic'), local('PTSerif-BoldItalic'),
    url(./PTSerif/EJRQQgYoZZY2vCFuvAFT9gaQZyTfoOFC-I2irw.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'PT Serif';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: local('PT Serif Bold Italic'), local('PTSerif-BoldItalic'),
    url(./PTSerif/EJRQQgYoZZY2vCFuvAFT9gaQZy3foOFC-I2irw.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin-ext */
@font-face {
  font-family: 'PT Serif';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: local('PT Serif Bold Italic'), local('PTSerif-BoldItalic'),
    url(./PTSerif/EJRQQgYoZZY2vCFuvAFT9gaQZyffoOFC-I2irw.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'PT Serif';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: local('PT Serif Bold Italic'), local('PTSerif-BoldItalic'),
    url(./PTSerif/EJRQQgYoZZY2vCFuvAFT9gaQZynfoOFC-I0.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'PT Serif';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('PT Serif'), local('PTSerif-Regular'), url(./PTSerif/EJRVQgYoZZY2vCFuvAFbzr-_dSb_nco.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'PT Serif';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('PT Serif'), local('PTSerif-Regular'), url(./PTSerif/EJRVQgYoZZY2vCFuvAFSzr-_dSb_nco.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin-ext */
@font-face {
  font-family: 'PT Serif';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('PT Serif'), local('PTSerif-Regular'), url(./PTSerif/EJRVQgYoZZY2vCFuvAFYzr-_dSb_nco.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'PT Serif';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('PT Serif'), local('PTSerif-Regular'), url(./PTSerif/EJRVQgYoZZY2vCFuvAFWzr-_dSb_.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'PT Serif';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('PT Serif Bold'), local('PTSerif-Bold'),
    url(./PTSerif/EJRSQgYoZZY2vCFuvAnt66qfVyvVp8NAyIw.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'PT Serif';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('PT Serif Bold'), local('PTSerif-Bold'),
    url(./PTSerif/EJRSQgYoZZY2vCFuvAnt66qWVyvVp8NAyIw.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin-ext */
@font-face {
  font-family: 'PT Serif';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('PT Serif Bold'), local('PTSerif-Bold'),
    url(./PTSerif/EJRSQgYoZZY2vCFuvAnt66qcVyvVp8NAyIw.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'PT Serif';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('PT Serif Bold'), local('PTSerif-Bold'),
    url(./PTSerif/EJRSQgYoZZY2vCFuvAnt66qSVyvVp8NA.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
