:root {
  --card-width: 480px; /* 5 inches */
  --card-height: 288px; /* 3 inches */
  --card-width-sm: 160px;
  --card-height-sm: 96px;
}

.novel-card {
  display: flex;
  flex-direction: column;
  max-width: var(--card-width);
  min-height: var(--card-height);
  position: relative;
  font-family: var(--font-family-text);
  font-size: 14px;
  color: var(--text-color-normal);
  background: var(--page-background);
  border-radius: 2px;
  box-sizing: border-box;
  border: none;
  padding: 0;
}
.novel-card {
  margin: 8px;
  box-shadow: 0 0 6px 1px var(--card-shadow-color);
}
/* .novel-card.medium {
  width: @card-width / 2;
  height: @card-height / 2;
  min-height: 0;
} */
.novel-card.small {
  padding: 10px 16px;
  width: var(--card-width-sm);
  height: var(--card-height-sm);
  box-shadow: 0 1px 2px 1px var(--card-shadow-color);
  min-height: 0;
  align-items: center;
  justify-content: center;
  text-align: center;
  user-select: none;
  transition: all 0.15s ease-in-out;
}
.novel-card .label {
  position: absolute;
  left: -2px;
  right: -2px;
  bottom: 4px;
  height: 20px;
  border-radius: 3px 3px 1px 1px;
  background-color: var(--dabble-gray);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.4);
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.2), -1px 0 0 rgba(0, 0, 0, 0.1), 1px 0 0 rgba(0, 0, 0, 0.1);
  color: #fff;
  text-align: center;
  font-weight: bold;
  font-size: var(--font-size-xs);
}
.novel-card .label.blue {
  background-color: #57b6cf;
}
.novel-card .label.red {
  background-color: #cf5757;
}
.novel-card .label.yellow {
  background-color: var(--dabble-yellow);
}
.right-gutter h4 {
  margin: 0 8px;
  font-size: 16px;
  font-family: var(--font-family-text);
  font-weight: bold;
  color: var(--text-color-lighterer);
}

.novel-card.highlightable.small:hover {
  position: relative;
  transform: translate(0, -1px);
  box-shadow: 1px 4px 10px 1px var(--card-shadow-color);
}
.novel-card ul,
.novel-card ol {
  margin: 0;
}

.novel-card-new-btn {
  position: absolute;
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px dashed var(--text-color-lightest);
  color: var(--text-color-lightest);
  max-width: var(--card-width);
  height: var(--card-height);
  margin: 16px 0;
  border-radius: 2px;
  transition: all 0.15s ease-in-out;
}
.novel-card-new-btn::before {
  content: '';
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  background-color: var(--background);
  opacity: 0.3;
  transition: all 0.15s ease-in-out;
}

.novel-card-new-btn .icon {
  color: var(--text-color-lighterer);
  font-size: 50px;
  line-height: 1;
}
.novel-card-new-btn:hover {
  border-color: var(--text-color-lighterer);
}
.novel-card-new-btn:hover .icon {
  color: var(--text-color-lighter);
}
.novel-card-new-btn:hover::before {
  background-color: var(--page-background);
}

.novel-card.drag-capture:not(:last-child):before {
  position: absolute;
  z-index: -1;
  content: '';
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: var(--page-background);
  border-radius: 2px;
  box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.2);
  transform: rotate(-1.5deg) translate(-2px, -4px);
}
.novel-card.drag-capture:after {
  position: absolute;
  z-index: -1;
  content: '';
  width: 100%;
  height: 100%;
  top: -13px;
  left: -10px;
  padding: 10px;
}
.novel-card.dragging,
.novel-card.dragging ~ .novel-card {
  display: none;
}

.novel-card .title {
  flex: 0 0 auto;
  min-height: 30px;
  border-bottom: 1px solid var(--red-line);
}
.novel-card .title h4 {
  font-family: var(--font-family-text);
  color: var(--text-color-normal);
  text-transform: none;
  margin: 0;
  font-weight: bold;
  padding: 6px 14px 0;
  line-height: 24px;
  font-size: 16px;
}
.novel-card .text {
  flex: 1 1 auto;
  padding: 2px 14px 4px;
  color: var(--text-color-normal);
  font-size: 15px;
  line-height: 25px;
  background-image: repeating-linear-gradient(
    to bottom,
    transparent,
    transparent 24px,
    var(--blue-line) 24px,
    var(--blue-line) 25px
  );
  background-attachment: local;
}
.novel-card .text p {
  margin: 0;
  line-height: inherit;
}

.novel-card .trash {
  display: none;
  position: absolute;
  bottom: 2px;
  right: 4px;
  padding: 2px;
  color: var(--text-color-lightest);
  font-size: 12px;
  cursor: pointer;
}
.novel-card:hover .trash {
  display: block;
}
.novel-card .trash:hover {
  color: var(--text-color-light);
}
