/* cyrillic-ext */
@font-face {
  font-family: 'Alegreya';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local('Alegreya Italic'), local('Alegreya-Italic'),
    url(./Alegreya/4UaHrEBBsBhlBjvfkSLk96fp57F2IwN-Pw.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Alegreya';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local('Alegreya Italic'), local('Alegreya-Italic'),
    url(./Alegreya/4UaHrEBBsBhlBjvfkSLk967p57F2IwN-Pw.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin-ext */
@font-face {
  font-family: 'Alegreya';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local('Alegreya Italic'), local('Alegreya-Italic'),
    url(./Alegreya/4UaHrEBBsBhlBjvfkSLk96Tp57F2IwN-Pw.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Alegreya';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local('Alegreya Italic'), local('Alegreya-Italic'),
    url(./Alegreya/4UaHrEBBsBhlBjvfkSLk96rp57F2IwM.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Alegreya';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: local('Alegreya Bold Italic'), local('Alegreya-BoldItalic'),
    url(./Alegreya/4UaErEBBsBhlBjvfkSLk_xHM8pFULilENlYQ_Q.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Alegreya';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: local('Alegreya Bold Italic'), local('Alegreya-BoldItalic'),
    url(./Alegreya/4UaErEBBsBhlBjvfkSLk_xHM8phULilENlYQ_Q.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin-ext */
@font-face {
  font-family: 'Alegreya';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: local('Alegreya Bold Italic'), local('Alegreya-BoldItalic'),
    url(./Alegreya/4UaErEBBsBhlBjvfkSLk_xHM8pJULilENlYQ_Q.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Alegreya';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: local('Alegreya Bold Italic'), local('Alegreya-BoldItalic'),
    url(./Alegreya/4UaErEBBsBhlBjvfkSLk_xHM8pxULilENlY.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Alegreya';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Alegreya Regular'), local('Alegreya-Regular'),
    url(./Alegreya/4UaBrEBBsBhlBjvfkSLsx6jj4JN0EwI.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Alegreya';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Alegreya Regular'), local('Alegreya-Regular'),
    url(./Alegreya/4UaBrEBBsBhlBjvfkSLlx6jj4JN0EwI.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin-ext */
@font-face {
  font-family: 'Alegreya';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Alegreya Regular'), local('Alegreya-Regular'),
    url(./Alegreya/4UaBrEBBsBhlBjvfkSLvx6jj4JN0EwI.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Alegreya';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Alegreya Regular'), local('Alegreya-Regular'),
    url(./Alegreya/4UaBrEBBsBhlBjvfkSLhx6jj4JN0.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Alegreya';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Alegreya Bold'), local('Alegreya-Bold'),
    url(./Alegreya/4UaGrEBBsBhlBjvfkSpa4r3Dwp5eKQtGBlc.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Alegreya';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Alegreya Bold'), local('Alegreya-Bold'),
    url(./Alegreya/4UaGrEBBsBhlBjvfkSpa4r3Kwp5eKQtGBlc.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin-ext */
@font-face {
  font-family: 'Alegreya';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Alegreya Bold'), local('Alegreya-Bold'),
    url(./Alegreya/4UaGrEBBsBhlBjvfkSpa4r3Awp5eKQtGBlc.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Alegreya';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Alegreya Bold'), local('Alegreya-Bold'),
    url(./Alegreya/4UaGrEBBsBhlBjvfkSpa4r3Owp5eKQtG.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Alegreya Sans';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local('Alegreya Sans Italic'), local('AlegreyaSans-Italic'),
    url(./Alegreya/5aUt9_-1phKLFgshYDvh6Vwt7V9dsm1TpG1Lsyu7.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Alegreya Sans';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local('Alegreya Sans Italic'), local('AlegreyaSans-Italic'),
    url(./Alegreya/5aUt9_-1phKLFgshYDvh6Vwt7V9du21TpG1Lsyu7.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin-ext */
@font-face {
  font-family: 'Alegreya Sans';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local('Alegreya Sans Italic'), local('AlegreyaSans-Italic'),
    url(./Alegreya/5aUt9_-1phKLFgshYDvh6Vwt7V9dsW1TpG1Lsyu7.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Alegreya Sans';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local('Alegreya Sans Italic'), local('AlegreyaSans-Italic'),
    url(./Alegreya/5aUt9_-1phKLFgshYDvh6Vwt7V9dv21TpG1Lsw.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Alegreya Sans';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: local('Alegreya Sans Bold Italic'), local('AlegreyaSans-BoldItalic'),
    url(./Alegreya/5aUo9_-1phKLFgshYDvh6Vwt7V9VBEhGhE9GmRGyBaT8.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Alegreya Sans';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: local('Alegreya Sans Bold Italic'), local('AlegreyaSans-BoldItalic'),
    url(./Alegreya/5aUo9_-1phKLFgshYDvh6Vwt7V9VBEhGjU9GmRGyBaT8.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin-ext */
@font-face {
  font-family: 'Alegreya Sans';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: local('Alegreya Sans Bold Italic'), local('AlegreyaSans-BoldItalic'),
    url(./Alegreya/5aUo9_-1phKLFgshYDvh6Vwt7V9VBEhGh09GmRGyBaT8.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Alegreya Sans';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: local('Alegreya Sans Bold Italic'), local('AlegreyaSans-BoldItalic'),
    url(./Alegreya/5aUo9_-1phKLFgshYDvh6Vwt7V9VBEhGiU9GmRGyBQ.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Alegreya Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Alegreya Sans Regular'), local('AlegreyaSans-Regular'),
    url(./Alegreya/5aUz9_-1phKLFgshYDvh6Vwt7VdtvWdUhm97sg.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Alegreya Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Alegreya Sans Regular'), local('AlegreyaSans-Regular'),
    url(./Alegreya/5aUz9_-1phKLFgshYDvh6Vwt7V5tvWdUhm97sg.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin-ext */
@font-face {
  font-family: 'Alegreya Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Alegreya Sans Regular'), local('AlegreyaSans-Regular'),
    url(./Alegreya/5aUz9_-1phKLFgshYDvh6Vwt7VRtvWdUhm97sg.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Alegreya Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Alegreya Sans Regular'), local('AlegreyaSans-Regular'),
    url(./Alegreya/5aUz9_-1phKLFgshYDvh6Vwt7VptvWdUhm8.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Alegreya Sans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Alegreya Sans Bold'), local('AlegreyaSans-Bold'),
    url(./Alegreya/5aUu9_-1phKLFgshYDvh6Vwt5eFIqEd2i0VBuxOCBA.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Alegreya Sans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Alegreya Sans Bold'), local('AlegreyaSans-Bold'),
    url(./Alegreya/5aUu9_-1phKLFgshYDvh6Vwt5eFIqE52i0VBuxOCBA.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin-ext */
@font-face {
  font-family: 'Alegreya Sans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Alegreya Sans Bold'), local('AlegreyaSans-Bold'),
    url(./Alegreya/5aUu9_-1phKLFgshYDvh6Vwt5eFIqER2i0VBuxOCBA.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Alegreya Sans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Alegreya Sans Bold'), local('AlegreyaSans-Bold'),
    url(./Alegreya/5aUu9_-1phKLFgshYDvh6Vwt5eFIqEp2i0VBuxM.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
