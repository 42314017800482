/* latin-ext */
@font-face {
  font-family: 'Courier Prime';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local('Courier Prime Italic'), local('CourierPrime-Italic'),
    url(./CourierPrime/u-4n0q2lgwslOqpF_6gQ8kELawRZVsf6hPvhPUWH.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Courier Prime';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local('Courier Prime Italic'), local('CourierPrime-Italic'),
    url(./CourierPrime/u-4n0q2lgwslOqpF_6gQ8kELawRZWMf6hPvhPQ.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Courier Prime';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: local('Courier Prime Bold Italic'), local('CourierPrime-BoldItalic'),
    url(./CourierPrime/u-4i0q2lgwslOqpF_6gQ8kELawRR4-Lvp9nsF3-OA6Fw.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Courier Prime';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: local('Courier Prime Bold Italic'), local('CourierPrime-BoldItalic'),
    url(./CourierPrime/u-4i0q2lgwslOqpF_6gQ8kELawRR4-LvqdnsF3-OAw.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Courier Prime';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Courier Prime Regular'), local('CourierPrime-Regular'),
    url(./CourierPrime/u-450q2lgwslOqpF_6gQ8kELaw9pWs39pvnRPA.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Courier Prime';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Courier Prime Regular'), local('CourierPrime-Regular'),
    url(./CourierPrime/u-450q2lgwslOqpF_6gQ8kELawFpWs39pvk.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Courier Prime';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Courier Prime Bold'), local('CourierPrime-Bold'),
    url(./CourierPrime/u-4k0q2lgwslOqpF_6gQ8kELY7pMT-7fq9PrNX2-Ag.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Courier Prime';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Courier Prime Bold'), local('CourierPrime-Bold'),
    url(./CourierPrime/u-4k0q2lgwslOqpF_6gQ8kELY7pMT-Dfq9PrNX0.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: 'Courier Prime Sans';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local('Courier Prime Italic'), local('CourierPrime-Italic'),
    url(./CourierPrime/CourierPrimeSansItalic.woff2) format('woff2');
}
/* latin */
@font-face {
  font-family: 'Courier Prime Sans';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: local('Courier Prime Bold Italic'), local('CourierPrime-BoldItalic'),
    url(./CourierPrime/CourierPrimeSansBoldItalic.woff2) format('woff2');
}
/* latin */
@font-face {
  font-family: 'Courier Prime Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Courier Prime Regular'), local('CourierPrime-Regular'),
    url(./CourierPrime/CourierPrimeSansRegular.woff2) format('woff2');
}
/* latin */
@font-face {
  font-family: 'Courier Prime Sans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Courier Prime Bold'), local('CourierPrime-Bold'),
    url(./CourierPrime/CourierPrimeSansBold.woff2) format('woff2');
}
