@media screen {
  html[data-theme='dark'] {
    --background: url(./images/textured-background-dark.jpg) top left / 1024px 683px repeat fixed #212121;
    --background-darkened: #333;
    --background-lightened: #424242;
    --dabble-gray: #666;

    --red-line: #663045;
    --blue-line: #33545f;
    --tooltip-highlight: #ffee00;

    --gray-dark: #ccc;
    --gray: #bbb;
    --gray-light: #828282;
    --gray-lighter: #444;
    --gray-lightest: #222;

    --modal-background: rgba(40, 40, 40, 0.7);
    --modal-shadow: 0 2px 8px rgba(255, 255, 255, 0.5);
    --modal-shadow-large: 0 3px 15px rgba(255, 255, 255, 0.3), 0 0 1px 1px rgba(255, 255, 255, 0.3);

    --tooltip-background: #999999;
    --tooltip-text-color: #222;

    --horizontal-bevel-top: 0 -1px #111, inset 0 1px #555;
    --horizontal-bevel: 0 1px #555, inset 0 -1px #111;
    --vertical-bevel: -1px 0 #111, inset 1px 0 #555;
    --home-background: #222;
    --home-project-shadow: 0 0 4px rgba(255, 255, 255, 0.3);
    --home-project-hover-shadow: 0 2px 12px 5px rgba(255, 255, 255, 0.3);

    --link-color: #257a83;
    --link-hover-color: #36b1bf;

    --white: #000;
    --black: #fff;
    --form-border: 1px solid rgba(255, 255, 255, 0.15);
    --page-background: #1e1e1e;
    --page-shadow: 0 0 8px rgba(0, 0, 0, 0.7), 0 0 0 1px rgba(0, 0, 0, 0.1);
    --folder-background: #333;
    --folder-shadow: 0 1px 8px rgba(0, 0, 0, 0.7), 0 0 0 1px rgba(0, 0, 0, 0.1);

    --dabble-blue: rgb(var(--dabble-blue-700));
    --dabble-light-teal: #282e30;
    --table-borders: #454d55;

    --text-color-normal: #bbb;
    --text-color-light: #b0b0b0;
    --text-color-lighter: #a3a3a3;
    --text-color-lighterer: #969696;
    --text-color-lightest: #777;
    --text-color-faint: #2e2e2e;
    --text-highlight: 0 1px 1px rgba(0, 0, 0, 0.8);

    --loading-background: #222;
    --loading-shimmer-light: #333;
    --loading-shimmer-dark: #444;

    --app-header-background: #3f3f3f; /* linear-gradient(to bottom, #e8e6e8, #d2d0d2); */
    --app-bar: #222;
    --status-bar-background: #3f3f3f;
    --side-nav-background: #292929;
    --side-nav-border: 1px solid #111;
    --side-nav-color-light: #444;
    --side-nav-color-mid: #555;
    --side-nav-color-dark: #666;
    --side-nav-hover-background: rgba(0, 0, 0, 0.08);
    --side-nav-selected-background: var(--dabble-blue);
    --side-nav-selected-text-color: #fff;
    --side-nav-icon-color: #999;
    --side-nav-arrow-hover-color: #222;
    --side-nav-arrow-selected-color: rgba(255, 255, 255, 0.7);
    --side-nav-arrow-selected-hover-color: #fff;
    --top-down-shadow: 0 2px 2px rgba(0, 0, 0, 0.5);

    --menu-background: #222;
    --menu-border-color: rgba(255, 255, 255, 0.5);
    --menu-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
    --menu-hover-color: rgba(255, 255, 255, 0.2);
    --menu-separator-color: #777;
    --menu-arrow-color: rgba(0, 0, 0, 0.5);

    --actions-shadow: -1px -1px 0 rgba(255, 255, 255, 0.2), 1px 1px 3px rgba(0, 0, 0, 0.6);
    --plot-hover-highlight: rgba(255, 255, 255, 0.025);
    --plot-first-line-highlight: rgba(0, 0, 0, 0.1);
    --card-shadow-color: rgba(255, 255, 255, 0.2);
    --tab-border-color: #333;
    --tab-hover-background: #222;

    --calendar-background: #333;
    --calendar-border: 1px solid #444;
  }

  html[data-theme='dark'] .loading-placeholder {
    background-image: linear-gradient(to right, #111 8%, #333 18%, #111 33%);
  }

  html[data-theme='dark'] .btn.success {
    filter: brightness(0.75);
  }

  html[data-theme='dark'] .btn.secondary {
    filter: invert(1);
  }

  html[data-theme='dark'] .logo,
  html[data-theme='dark'] .tagline {
    filter: invert(1) grayscale(1) brightness(0.8);
  }

  html[data-theme='dark'] .holidays .projects-screen .logo {
    filter: none;
  }

  html[data-theme='dark'] .holidays .tagline {
    filter: invert(1);
  }

  html[data-theme='dark'] .nano-logo {
    filter: brightness(0.8);
  }

  html[data-theme='dark'] .grammar-mark::before {
    filter: brightness(0.6);
  }

  html[data-theme='dark'] ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.2);
  }
  html[data-theme='dark'] ::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.05);
    box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.2);
  }
  html[data-theme='dark'] ::-webkit-scrollbar-track:window-inactive {
    box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.1);
  }
  html[data-theme='dark'] ::-webkit-scrollbar-thumb:window-inactive {
    background: rgba(255, 255, 255, 0.02);
    box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.1);
  }

  html[data-theme='dark'] .btn.primary {
    color: #ccc;
    background-color: rgba(var(--dabble-blue-700));
    border-color: rgba(var(--dabble-blue-800));
  }
  html[data-theme='dark'] .btn.primary:hover {
    color: #ccc;
    background-color: rgba(var(--dabble-blue-800));
    border-color: rgba(var(--dabble-blue-800));
  }
  html[data-theme='dark'] .btn.primary.disabled,
  html[data-theme='dark'] .btn.primary:disabled {
    background-color: rgba(var(--dabble-blue-700));
    border-color: rgba(var(--dabble-blue-800));
  }
  html[data-theme='dark'] .btn.primary:active,
  html[data-theme='dark'] .btn.primary.active,
  html[data-theme='dark'] .show > .btn.primary.dropdown-toggle {
    color: #ccc;
    background-color: rgba(var(--dabble-blue-800));
    border-color: rgba(var(--dabble-blue-900));
  }
}
