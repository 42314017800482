.nav-item.novel > header .title {
  font-weight: bold;
}
.nav-item.novel_manuscript > header .title,
.nav-item.novel_plots > header .title,
.nav-item.novel_characters > header .title,
.nav-item.novel_notes > header .title {
  font-weight: bold;
  text-transform: uppercase;
}
.paragraph-spacing-double {
  --paragraph-line-spacing: 2.4em;
}
.paragraph-spacing-half {
  --paragraph-line-spacing: 1.8em;
}
.paragraph-spacing-single {
  --paragraph-line-spacing: 1.2em;
}
.paragraph-style-book,
.paragraph-style-book-modified {
  --paragraph-indent: 2em;
  --paragraph-margin: 0;
}
.paragraph-style-book p:first-of-type {
  text-indent: 0;
}
.paragraph-style-modern {
  --paragraph-indent: 0;
  --paragraph-margin: 1.2em;
}
